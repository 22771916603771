body {
    background-color: #F0FBD0 !important;
    color: #333333 !important;
    font-family: "Montserrat", sans-serif;
    font-family: "Roboto", sans-serif;
}

#header_top {
    z-index: 1 !important;
}

.btn-icon:focus {
    box-shadow: none;
    outline: none;
}

.todolist_array {
    background-color: #e9f3fe;
    color: #0B7702;
    padding: 20px 40px;
    border-radius: 20px;
    position: relative;
}

.todolist_array .form-label {
    margin-bottom: 0px;
}

.food_castegory_img {
    width: 80px;
    height: 80px;
}

.food_castegory_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.food_castegory_imgelite {
    width: 20px !important;
    height: 20px !important;
    /* object-fit: contain !important; */
    color: #0B7702 !important;
}

.view_food_category {
    width: 100%;
    display: flex;
}

.view_food_category_name {
    display: flex;
    align-items: center;
    font-weight: 500;
    padding-left: 20px;
}

.viewFoodlogo {
    margin: 0px auto !important;
}

.fc-event-container .fc-event,
.fc-event-container .fc-event-dot {
    background-color: #fff0bf !important;
    color: #babdbf !important;
    border-color: #0B7702 !important;
}

.userFlag i {
    margin-bottom: 10px;
}

.right-allign-menu {
    float: right;
    padding-right: 19px;
    font-size: 20px !important;
}

.nav-link-gdc-selected {
    font-weight: bold !important;
}

.order_collp {
    border: 1px solid #0B7702 !important;
}

.collaspe_confirm {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.collaspe_confirm img {
    width: 100%;
    height: 100%;
}

.order_confirm_con {
    width: 40%;
    margin: auto;
    text-align: center;
}

.order_confirm_img {
    width: 100px;
    height: 100px;
    margin: auto;
}

.order_confirm_img img {
    width: 100%;
    height: 100%;
}

div.font-montserrat {
    font-family: "Montserrat", sans-serif;
}

div.font-roboto {
    font-family: "Robot", sans-serif;
}

div.font-opensans {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
}

/* div.rtl{ */
div.rtl {
    direction: rtl !important;
    text-align: right;
}

.sticky-top {
    width: calc(100% - 280px);
    position: fixed;
}

.marginTop {
    margin-top: 7.2% !important;
}

/* .apexcharts-legend {
    top: 7px !important;
} */
.media-body .revie_tit_v {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.breadcrump p {
    padding: 20px 20px 0px 20px;
    margin-bottom: 0px;
    display: flex;
    color: #0B7702 !important;
}

.breadcrump h6 {
    margin-bottom: 0px;
    padding-top: 4px;
    font-size: 13px;
    color: #0B7702 !important;
}

.fa-angle-right {
    font-size: 22px;
}

.highlights_breadcrump {
    font-weight: bolder !important;
    /* color: #F3FDE8 !important; */
    font-size: 14px;
    cursor: pointer;
}

.breadcrump span {
    padding: 0px 5px;
}

.site_pass {
    position: relative;
}

.site_pass .form-control {
    position: relative;
}

.site_pass i {
    position: absolute;
    right: 10px;
    top: 10px;
}

.add_category .card-header {
    padding: 5px 0px;
}

.add_category .card-body {
    padding: 5px 0px;
}

.category_pad {
    padding: 0px !important;
}

.inspection_row {
    height: 82px !important;
}

/* .inspection_row .table_text_left {
    width: 270px;
} */
.Guest_invite_section .guest_invite .card-body .bf_images {
    width: 100px;
    height: 100px;
    margin: auto;
}

.Guest_invite_section .guest_invite .card-body .bf_images img {
    width: 100%;
    height: 100%;
}

.guest_invite_header .btn:focus {
    box-shadow: none;
}

.guest_invite_header .btn {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: bold;
    color: #0B7702;
    padding: 14px;
    position: relative;
}

.guest_invite_header_Invite .btn {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #0B7702;
    align-items: center;
    height: 75px;
    position: relative;
}

.card_content_food {
    margin-top: 70px;
}

.guest_invite_header .food_count {
    width: 35px;
    height: 35px;
    background-color: #13b686;
    display: inline-block;
    border-radius: 50%;
    padding: 5px;
    position: absolute;
    right: -13px;
    top: -16px;
    font-size: 15px;
}

.guest_nav_sec {
    z-index: 999;
    background-color: #F3FDE8 !important;
    box-shadow: none;
}

.food_count_no {
    position: relative;
    left: 7px;
    top: 2px;
    color: white;
}

.Guest_invite_section .guest_invite .card-body .form-check-label {
    font-weight: 500;
    padding-top: 3px;
    cursor: pointer;
}

.Guest_invite_section .guest_invite .card-body .form-check-input {
    font-size: 20px;
    border: 1px solid #333333;
}

.Guest_invite_section .guest_invite .card-body .form-check-input:checked[type="checkbox"] {
    background-color: #0B7702;
}

.Guest_invite_section .guest_invite .radio_btn .form-check-input {
    border-radius: 50% !important;
}

.Guest_invite_section .guest_invite .radio_btn .form-check-input:checked[type="radio"] {
    background-color: #0B7702;
}

.Guest_invite_section .guest_invite .guest_invite_submit_btn button {
    background-color: #0B7702;
    padding: 8px 25px;
    color: white;
    font-weight: 500;
    border-radius: 10px;
}

.guest_nav_sec .navbar .guest_bf_logo {
    height: auto;
    width: auto;
}

.guest_nav_sec .navbar .navbar-brand .guest_invite_img {
    height: auto;
    width: 400px;
}

.guest_nav_sec .navbar .navbar-brand .guest_invite_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.guest_nav_sec .navbar {
    display: flex;
    justify-content: end;
    padding: 10px 0px;
}

.guest_invite_submit_btn {
    text-align: center;
}

.guest_invite_footer {
    background-color: #F3FDE8 !important;
}

.guest_invite_footer .footer-left {
    display: flex;
    align-items: center;
}

.guest_invite_footer .footer-left p a {
    color: #0B7702 !important;
}

.guest_invite_footer .footer {
    display: flex;
    justify-content: space-between;
}

.guest_invite_footer .footer .footer-right {
    width: 100px;
    height: 100px;
}

.guest_invite_footer .footer .footer-right img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.footer {
    display: none;
}

/* .image_size_fd {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.image_size_fd .img_size {

}
.image_size_fd .rating {
    display: flex;
    align-items: center;
}
.image_size_fd .name {
    display: flex;
    align-items: center;
} */
/* ================updated===design======================== */
.commor_save {
    background-color: #0B7702 !important;
    color: white !important;
    float: right;
    /* width: 140px; */
    height: 45px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 10px !important;
}

.assign_edit_save {
    background-color: #0B7702 !important;
    color: white !important;
    /* float: right; */
    /* width: 140px; */
    height: 45px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit_view_in_poss {
    display: flex;
    justify-content: end;
    position: relative;
    left: 8px;
}

.card-footer .commor_save {
    background-color: #0B7702 !important;
    color: white !important;
    float: right;
    /* width: 140px; */
    height: 45px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 10px !important;
}

.btn-secondary {
    float: right;
    height: 45px !important;
    /* width: 115px !important; */
    font-size: 16px !important;
    font-weight: 600;
    border-radius: 10px !important;
    border: 1px solid #6e6e6e;
    color: #0B7702 !important;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
}

.common_top_section .card {
    height: 121px;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    border: 1px solid #dadada;
    border-radius: 20px;
}

.dashbord_icons {
    width: 35px;
    height: 35px;
    border: 1px solid #6e6e6e;
    border-radius: 50%;
    padding: 6px;
    margin: 0 auto;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    position: relative;
}

.his_icon {
    width: 35px;
    height: 35px;
    border: 1px solid #6e6e6e;
    border-radius: 50%;
    padding-top: 3px;
    margin: 0 auto;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    display: inline-block;
}

.dashbord_icons i {
    font-size: 20px;
}

.his_icon i {
    font-size: 20px;
}

.my_sort_cut .dash_total {
    color: #0B7702;
    font-weight: 500;
    padding-top: 4px;
    font-size: 16px;
}

.his_total {
    color: #0B7702;
    font-weight: 500;
    padding-top: 4px;
    font-size: 16px;
}

.ribbon-box.active_dash {
    background-color: #449bed;
}

.ribbon .ribbon-box.active_dash::before {
    border-color: #449bed !important;
    border-right-color: transparent !important;
}

.ribbon-box.inactive_dash {
    background-color: #444444;
}

.ribbon .ribbon-box.inactive_dash::before {
    border-color: #444444 !important;
    border-right-color: transparent !important;
}

.user_sec .icon-user {
    border: 1px solid #6e6e6e;
    padding: 8px;
    border-radius: 50%;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
}

.rounded_icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1.5px solid #0B7702;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    margin-right: 10px;
    position: relative;
    color: #0B7702;
}

::-webkit-file-upload-button {
    background-color: #d7eaff !important;
    color: #0B7702 !important;
}

.rounded_icon i,
.rounded_icon .rounded_icon_r {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: #0B7702;
}

.rounded_icon .rounded_icon_r {
    font-size: 25px;
}

.last_log_icon {
    padding: 10px 9px;
    border-radius: 50%;
    border: 1.5px solid #0B7702;
    color: #0B7702 !important;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    margin-right: 10px;
}

.rememer_pwd {
    font-size: 15px !important;
    color: #0B7702;
    margin-left: 5px;
    margin-top: 4px;
}

input {
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    border-radius: 14px !important;

}

.btn-primary {
    height: 45px !important;
    width: auto !important;
    border-radius: 14px !important;
}

.export_btn_grid i {
    color: #0B7702;
}

.order_collapse {
    width: 100%;
    float: left;
}

.order_con_1 {
    width: 15%;
    float: left;
    text-align: left;
}

.order_con_2 {
    width: 85%;
    float: left;
    text-align: left;
}

@media only screen and (max-width: 992px) {
    .order_confirm_con {
        width: 100%;
    }

    .media_order .card-body {
        padding: 0px;
    }

    .guest_invite_heading {
        text-align: center !important;
    }

    .guest_dynamic_text {
        margin-bottom: 20px;
        text-align: justify;
    }

    .collaspe_confirm {
        margin-top: 12px;
    }

    .order_con_2 {
        width: 75%;
        float: left;
        text-align: left;
        margin-left: 20px;
    }

    .common_loader_mobile_view {
        position: absolute !important;
        top: 25% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        z-index: 999 !important;
    }
}

.card .card-options a.btn {
    border: 1px solid #6e6e6e;
    color: #0B7702 !important;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
}

.card .card-options .btn-primary {
    height: 45px !important;
    width: 98px !important;
    border-radius: 14px !important;
}

.confirm_next_order {
    width: 150px !important;
}

.order_full {
    width: auto !important;
    margin-top: 20px !important;
    background-color: #6c757d !important;
}

.card .card-options .dropdown-toggle {
    height: 45px !important;
    width: 115px !important;
    border-radius: 10px !important;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    color: #0B7702 !important;
    padding: 0px 16px;
}

.locationButton {
    height: 40px !important;
    width: 100px !important;
    border-radius: 7px !important;
    font-size: 18px;
    padding: 3px;
    background-color: #0B7702 !important;
    color: white !important;
    font-weight: 500;
}

.location_add_btn {
    height: 40px !important;
    width: 100px !important;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%) !important;
    color: #0B7702 !important;
    padding: 3px;
    border: 1px solid #6e6e6e;
    font-size: 20px;
    font-weight: bolder;

    /* height: 34px !important;
    width: 90px !important;
    border-radius: 7px !important;
    font-size: 18px;
    padding: 3px;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%) !important;
    color: white !important;
    font-weight: 500; */
}

.card .card-options .dropdown-menu {
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
}

.css-1s2u09g-control {
    height: auto;
    border-radius: 14px !important;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #0B7702 !important;
    color: white !important;
}

.fc .fc-button-primary {
    background-color: #F3FDE8 !important;
    color: #0B7702 !important;
}

.css-16xfy0z-control {
    height: 40px !important;
    border-radius: 14px !important;
}

.css-13cymwt-control {
    height: auto !important;
    border-radius: 14px !important;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%) !important;
}

.ag-header-row-column {
    background-color: #F3FDE8 !important;
}

.ag-header-row-floating-filter {
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
}

.ag-row {
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    display: flex !important;
    align-items: center !important;
}

.ag-cell-auto-height {
    display: flex;
}

.badge-warning {
    color: #d45050;
    border: 1.5px solid #d45050;
    background-color: white;
    font-weight: 500;
    width: 92px;
    height: 36px;
    display: flex;
    justify-content: center;
    font-size: 16px;
    align-items: center;
}

.badge-default {
    color: #6e6e6e;
    border: 1.5px solid #6e6e6e;
    background-color: white;
    font-weight: 500;
    width: 92px;
    height: 36px;
    display: flex;
    justify-content: center;
    font-size: 16px;
    align-items: center;
    /* padding-top: 7px; */
}

.duration_scroll {
    height: 230px;
    overflow: auto;
}

.hotel_inactive {
    margin: 25px auto 0px;
    /* color: #fff; */
    /* border: 1.5px solid #6e6e6e; */
    /* background-color: 6e6e6e; */
    font-weight: 500;
    width: 92px;
    height: 36px;
    display: flex;
    justify-content: center;
    font-size: 16px;
    align-items: center;

    /* padding-top: 7px; */
}

/* .ag-cell {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
} */
/* 
.ag-header-cell-label {
    justify-content: left !important;
} */
/* .grid-cell-centered {
    text-align: right;
} */

/* .ag-right-aligned-header {
    display: flex !important;
    justify-content: center !important;
} */

.ag-center-header .ag-header-cell-label {
    justify-content: center;
}

.tag-green {
    color: #13b686;
    border: 1.5px solid #13b686;
    background-color: white;
    font-weight: 500;
    width: 92px !important;
    height: 36px !important;
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.tag-danger {
    color: #d45050;
    border: 1.5px solid #d45050;
    background-color: white;
    font-weight: 500;
    width: 92px !important;
    height: 36px !important;
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.card-footer .cancel_btn {
    height: 45px !important;
    width: 98px !important;
    border-radius: 14px !important;
}

.modal_btn .ok_btn {
    height: 34px !important;
    width: 90px !important;
    border-radius: 7px !important;
    font-size: 18px;
    padding: 3px;
    background-color: #0B7702 !important;
    color: white !important;
    font-weight: 500;
}

.swal2-confirm {
    height: 34px !important;
    width: 90px !important;
    border-radius: 7px !important;
    font-size: 18px;
    background-color: #0B7702 !important;
    color: white !important;
    font-weight: 500;
    padding: 0px !important;
    font-weight: bold;
}

.modal_ok {
    height: 34px !important;
    width: 90px !important;
    border-radius: 7px !important;
    font-size: 15px;
    background-color: #0B7702 !important;
    color: white !important;
    font-weight: 500;
    padding: 0px !important;
    font-weight: bold;
}

.modal_cancel {
    height: 34px !important;
    width: 90px !important;
    border-radius: 7px !important;
    font-size: 15px;
    background-color: white !important;
    color: #0B7702;
    margin: 0px 10px;
    border: 1px solid #6e6e6e;
    font-weight: 500;
    padding: 0px !important;
    border: 1px solid #6e6e6e !important;
    color: #0B7702 !important;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    font-weight: bold;
}

.swal2-cancel {
    height: 34px !important;
    width: 90px !important;
    border-radius: 7px !important;
    font-size: 18px;
    background-color: white !important;
    color: #0B7702;
    margin: 0px 10px;
    border: 1px solid #6e6e6e;
    font-weight: 500;
    padding: 0px !important;
    border: 1px solid #6e6e6e !important;
    color: #0B7702 !important;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    font-weight: bold;
}

.swal2-deny {
    height: 34px !important;
    width: 90px !important;
    border-radius: 7px !important;
    font-size: 18px;
    background-color: #dc3741 !important;
    color: white !important;
    margin: 0px 10px;
    border: 1px solid #6e6e6e;
    font-weight: 500;
    padding: 0px !important;
    border: 1px solid #6e6e6e !important;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    font-weight: bold;
}

.user_profile {
    width: 40px;
    height: 40px;
    outline: 1.5px solid #6e6e6e;
    object-fit: cover;
    margin-right: 10px;
}

.modal_btn {
    display: flex;
    justify-content: center;
}

.modal_btn .cancel_btn {
    height: 34px !important;
    width: 90px !important;
    border-radius: 7px !important;
    padding: 3px;
    font-size: 18px;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    color: #0B7702;
    margin: 0px 10px;
    border: 1px solid #6e6e6e;
    font-weight: 500;
}

#statusChange .modal-content {
    border-radius: 14px !important;
}

.close_border {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2px solid #6e6e6e;
    margin-bottom: 0px;
}

#statusChange .modal-body {
    padding: 40px;
    display: flex;
    justify-content: space-between;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    border-radius: 14px !important;
    width: 560px;
    height: 219px;
}

.edit_user_table {
    background-color: #F3FDE8 !important;
}

.custom-switch-indicator {
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    border: 1px solid #6e6e6e;
    padding: 3px;
}

.custom-switch-indicator:before {
    background-color: #8192a5;
}

.show-fetch {
    z-index: 0 !important;
    border: 1px solid #6e6e6e !important;
    border-radius: 7px;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    color: #444444;
    font-size: 16px;
    font-weight: 500;
}

.custom-switch-description {
    font-weight: 500;
    font-size: 16px;
}

thead {
    height: 52px !important;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
}

.table,
tr {
    border: 1px solid #ccc;
}

tbody,
td,
tfoot,
tr {
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    height: 66px !important;
    border-bottom: 1.1px solid #ccc;
}

.view_hotel_card {
    border-radius: 14px !important;
    background: #e9f3fe;
    padding: 20px !important;
}

.view_hotel_sec .card-header {
    background-color: #F3FDE8 !important;
    border-radius: 10px 10px 0px 0px;
    height: 70px;
    font-size: 18px;
    font-weight: bold;
}

.latitude_location {
    color: #0B7702;
    font-size: 18px;
    padding-top: 15px;
    margin-bottom: 0px !important;
}

.hotel_contact tr {
    height: 55px !important;
}

.hotel_contact td {
    padding: 5px 10px !important;
}

/* ======== */
#disableModal .modal-content {
    border-radius: 14px !important;
}

.scroll_todo {
    height: 200px;
    /* padding:20px; */
    overflow: auto;
}

.scroll_todo_auto {
    height: auto;
    /* padding:20px; */
    overflow: auto;
}

.update_button_moving {
    margin-right: 30px !important;
}

#disableModal .modal-body {
    padding: 40px;
    display: flex;
    justify-content: space-between;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    border-radius: 14px !important;
    width: 560px;
    height: 219px;
}

#disableModal .modal-body .logo {
    width: 100px;
    padding-top: 5px;
}

.swal_content_text p {
    font-size: 25px;
    text-align: center;
    font-weight: bold;
    color: #0B7702;
}

.ag-input-field-input {
    width: 178px;
    /* border-radius: 14px !important; */
    height: 30px;
}

#statusChange .modal-body .logo {
    width: 100px;
    padding-top: 5px;
}

#viewmodal.show .modal-content {
    transform: none !important;
    position: relative;
    border-radius: 14px !important;
}

#viewmodal.show .modal-header {
    border-radius: 14px 14px 0px 0px !important;
}

.table_text_left {
    color: #0B7702;
    font-weight: 600;
    font-size: 18px;
    padding-top: 15px;
    margin-bottom: 0px !important;
    display: inline-block;
    /* width: 247px; */
}

.qr_view_title {
    color: #0B7702;
    font-weight: 600;
    font-size: 25px;
}

.table_text_left1 {
    color: #444444;
    font-weight: 600;
    font-size: 18px;
    padding-top: 7px;
    margin-bottom: 0px !important;
    display: inline-block;
    font-weight: bolder;
}

.table_colon {
    padding-top: 15px;
    font-weight: bolder;
}

.table_text_right {
    color: #444444;
    font-size: 18px;
    padding-top: 14px;
    margin-bottom: 0px !important;
}

.hotel_sec .card {
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    border: 1px solid #dadada;
    border-radius: 20px;
}

.search_hotel_select {
    width: 406px !important;
}

.modal-header {
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
}

.modal-content {
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    border-radius: 20px;
}

.modal-header {
    border-radius: 20px 20px 0px 0px;
}

.User_part_left {
    padding: 10px;
}

.User_part_right {
    font-size: 18px;
}

.User_part_left .category_name {
    padding: 5px 8px;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    border: 1px solid #6e6e6e;
    border-radius: 8px;
    font-weight: bold;
    color: #0B7702;
}

.user_hotel_logo {
    width: 150px;
    height: 150px;
    margin-top: 20px;
}

.user_hotel_logo {
    width: 100%;
    height: auto;
}

.userView .col-lg-5 {
    border-right: 1px solid #6e6e6e;
}

.userView .calendarborder {
    border-right: 1px solid #6e6e6e;
}

.bor-right {
    border-right: 1px solid #6e6e6e;
}

.userView .user_hotel_logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.todo_increment {
    font-size: 35px;
    margin-top: 20px;
}

.todo_increment span {
    margin: 0px 10px;
    cursor: pointer;
    color: #0B7702;
}

.Edit_icon_inventry:before {
    color: white !important;
    padding: 0px 5px;
    font-size: 18px;
}

.assign_icon:before {
    color: white !important;
    padding: 0px 5px;
    font-size: 18px;
}

.User_part_right .hotel_name {
    font-weight: bold;
    font-size: 18px;
    color: #0B7702;
    margin-bottom: 0px;
    padding-top: 10px;
}

.User_part_right label {
    font-size: 18px;
    color: #0B7702;
    font-weight: 600;
    margin-bottom: 0px;
}

.User_part_right span {
    font-size: 18px;
    color: #444444;
    font-weight: 500;
}

.swal_image {
    max-width: 100%;
    margin: 15px auto 10px !important;
    position: absolute;
    left: 40px;
    top: 65px;
}

.swal_pop {
    background: linear-gradient(to top, #F3FDE8 10%, #fff 35%) !important;
    border-radius: 14px !important;
    width: 560px !important;
    height: 219px !important;
    border-radius: 14px !important;
    border: 1px solid #6e6e6e !important;
    padding: 10px;
}

.swal_title_front_desk {
    color: #0B7702 !important;
    font-size: 20px !important;
    padding: 4.3rem 4rem 0rem 7rem !important;
}

.swal_title {
    color: #0B7702 !important;
    font-size: 25px !important;
    padding: 5rem 1rem 0rem 4rem !important;
}

.swal_title_bulkmsg {
    padding: 2rem 1rem 0rem 4rem !important;
    color: #0B7702 !important;
    font-size: 25px !important;
}

.swal_title_surebulk {
    padding: 3rem 1rem 0rem 4rem !important;
    color: #0B7702 !important;
    font-size: 25px !important;
}

.view_ticket .card-header {
    background-color: #e9f3fe;
    border-radius: 10px 10px 0px 0px;
    font-size: 18px !important;
    color: #0B7702;
    font-weight: 500 !important;
}

.tally_cash_pagagraph_scroll {
    height: 80px;
    overflow: scroll;
}

.view_ticket .card-header .h6 {
    font-weight: bold;
    color: #0B7702;
}

.form-check-input {
    border-radius: 0.25em;
}

.view_ticket .detail_table {
    color: #444444;
    font-size: 16px;
    padding-top: 7px;
    margin-bottom: 0px !important;
    font-weight: bold;
}

.text-right .text-muted {
    color: #6c757d !important;
}

.view_ticket .text-right .text-muted {
    margin-top: 9px;
    display: inline-block;
}

.view_ticket_dec {
    background: linear-gradient(to top, #F3FDE8 10%, #fff 35%) !important;
    border: 1px solid #6e6e6e;
    border-radius: 10px;
    padding: 25px;
}

.view_ticket_dec .h6 {
    color: #0B7702;
    font-weight: bolder !important;
    font-size: 18px !important;
}

/* .purpose_desc {
    height: 300px;
    overflow: scroll;
    display: inline-block;
} */
.view_job_images {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.view_job_images img {
    width: 100%;
    height: 100%;
}

.view_ticket_dec p {
    color: #6c757d !important;
    font-weight: bolder;
}

.ticket_history .card-body {
    background-color: #F3FDE8 !important;
    border-radius: 0px 0px 10px 10px !important;
}

.timeline_item .text-right {
    font-weight: bold;
    color: #0B7702;
}

.text-right .text-muted {
    color: #0B7702 !important;
    font-size: 16px !important;
    font-weight: bold;
}

.left_ticket {
    background: linear-gradient(to top, #F3FDE8 10%, #fff 35%) !important;
    padding: 10px;
    color: #0B7702;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid #6e6e6e;
    width: 130px;
    height: 45px;
    font-weight: bold;
    text-align: center;
}

.right_ticket {
    background: #0B7702;
    padding: 10px;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
    width: 100px;
    height: 45px;
    margin-left: 20px;
    font-weight: bold;
    text-align: center;
}

.right_ticket i {
    margin-left: 6px !important;
}

.limit_select {
    width: 245px;
}

.dashboard_top_reviews th {
    background-color: #F3FDE8 !important;
    font-weight: bold !important;
}

.view_user_modal {
    min-width: 50%;
}

.view_user_modal .modal-body {
    padding: 30px;
}

textarea {
    background: linear-gradient(to top, #F3FDE8 10%, #fff 35%) !important;
}

.userView .ribbon .ribbon-box.orange {
    position: absolute;
    left: -41px;
    top: -12px;
}

.userView .user_hotel_logo {
    width: 100px;
    height: 100px;
    margin: 30px auto;
}

.userView .user_hotel_logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.ticket_report .card-header {
    background-color: #e9f3fe;
    border-radius: 10px 10px 0px 0px;
}

.media_images_link {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
}

.media_images_link li {
    display: inline-block;
    padding: 0px 45px;
}

.User_part_right .hotel_name {
    text-align: center;
}

/* ===========ticket status color style =========== */
.ticket_open,
.ticket_assigned,
.ticket_inprogress,
.ticket_inreview,
.ticket_reopened,
.ticket_closed,
.ticket_completed {
    align-items: center;
    display: inline-block;
    font-weight: 500 !important;
    width: 90px !important;
    height: 35px !important;
    font-size: 13px !important;
    border-radius: 6px !important;
    padding-top: 3px !important;
    text-align: center;
}

.ticket_Booking_Offer {
    align-items: center;
    display: inline-block;
    font-weight: 500 !important;
    width: 100px !important;
    height: 35px !important;
    font-size: 13px !important;
    border-radius: 6px !important;
    padding-top: 3px !important;
    text-align: center;
}

.ticket_open {
    background-color: #a8e1ff;
    color: #444444;
}

.ticket_assigned {
    background-color: #28e3ff;
    color: #444444;
    font-weight: 500;
}

.ticket_inprogress {
    background-color: #eeeaaf;
    color: #444444;
    font-weight: 500;
}

.ticket_inreview {
    background-color: #f5d7bb;
    color: #444444;
    font-weight: 500;
}

.ticket_reopened {
    background-color: #a9e8c1;
    color: #444444;
    font-weight: 500;
}

.ticket_closed {
    background-color: #cec6c0;
    color: #fff;
    font-weight: 500;
}

.ticket_Booking_Offer {
    background-color: rgb(174 145 253);
    color: #ffff;
    font-weight: 500;
}

.ticket_completed {
    background-color: #c4e4a5;
    color: #444444;
    font-weight: 500;
}

.ticket_Rejectes {
    background-color: #c4e4a5;
    color: #cec6c0;
    font-weight: 500;
}

.list_ticket .ribbon {
    border-radius: 10px !important;
}

.list_ticket .ribbon .ribbon-box.orange {
    background: linear-gradient(to top, #F3FDE8 10%, #fff 35%) !important;
    color: #0B7702;
    font-weight: bold;
}

.list_ticket .ribbon .ribbon-box.orange::before {
    border-color: #f6f7f9 !important;
    border-right-color: transparent !important;
}

.latest_review_modal {
    min-width: 70% !important;
}

.modal-lg {
    min-width: 70% !important;
}

.review_url {
    text-decoration: underline;
}

.media_images {
    width: 50px;
    height: 50px;
}

.media_images img {
    width: 100%;
    height: 100%;
}

.ag-paging-panel {
    background: linear-gradient(to top, #F3FDE8 10%, #fff 35%) !important;
}

/* .ag-paging-row-summary-panel {
    color: #444444 !important;
} */

.ag-paging-page-summary-panel {
    font-size: 18px !important;
    margin: 0px !important;
    font-weight: bolder !important;
}

.dashboard_top_reviews tr th {
    font-weight: 800 !important;
}

.ag-theme-alpine .ag-icon-previous::before {
    font-size: 18px !important;
    font-weight: bolder !important;
}

.ag-theme-alpine .ag-icon-next::before {
    font-size: 18px !important;
    font-weight: bolder !important;
}

.ag-theme-alpine .ag-paging-panel>* {
    font-size: 18px !important;
}

.fetch_btn {
    font-weight: bold !important;
    background: linear-gradient(to top, #F3FDE8 10%, #fff 35%) !important;
    color: #444444;
}

.view_logo {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 10px;
}

.view_logo img {
    width: 100%;
    height: 100%;
}

.review_Logo {
    width: 25px;
    height: 25px;
    display: inline-block;
    margin-right: 10px;
}

.review_Logo img {
    width: 100%;
    height: 100%;
}

.ag-paging-row-summary-panel #ag-17-to,
#ag-17-of {
    font-weight: bolder !important;
}

.ticket_roport .card-header {
    background-color: #e9f3fe;
    border-radius: 10px 10px 0px 0px;
}

/* ========================================================== */
.header_top.dark {
    background-color: #0B7702 !important;
}

.sidebar_dark #left-sidebar {
    background-color: #F3FDE8 !important;
}

.sidebar_dark .sidebar .metismenu .active>a {
    color: #0B7702 !important;
    border-color: #0B7702;
}

.brand-name {
    color: #0B7702 !important;
}

.metismenu a:hover {
    color: #0B7702;
    border-color: #0B7702;
}

.card-body .clearfix button {
    background-color: #0B7702;
    border-color: #0B7702;
}

#left-sidebar {
    border-right: 1px solid white;
}

.ribbon .ribbon-box.orange {
    background-color: #0B7702 !important;
}

.ribbon .ribbon-box.orange::before {
    border-color: #0B7702 !important;
    border-right-color: transparent !important;
}

.fa-eye,
.fa-arrow-up,
.fa-arrow-down {
    color: #0B7702 !important;
    font-size: 25px;
}

.fa-eye::before,
.fa-arrow-up::before,
.fa-arrow-down::before {
    color: #0B7702 !important;
}

.fa .fa-send {
    color: green !important;
    font-size: 20px !important;
}

.fa-arrow-up,
.fa-arrow-down,
.fa-edit,
.fa-refresh,
.fa-wrench,
.fa-user-plus,
.fa-commenting,
.fa-print {
    /* color: #0B7702 !important; */
    font-size: 23px !important;
}

.notification .fa-envelope {
    color: #0B7702 !important;
    font-size: 25px !important;
}

.notification .fa {
    font-size: 25px;
    color: #0B7702;
}

.fa-trash-o {
    color: "#0B7702" !important;
    font-size: 25px !important;
}

.fa-file-text-o {
    color: #0B7702 !important;
    font-size: 17px !important;
}

.metismenu a {
    color: #0B7702;
    font-size: 16px !important;
}

#page_top {
    background-color: #F3FDE8 !important;
    height: 90px;
}

.page-header {
    border-bottom: none;
}

.page-header .left .logo .img_size img {
    object-fit: contain;
}

/* .page-header .right .notification .dropdown a:hover {
    color: white !important;
} */
.badge-primary {
    background-color: red !important;
}

.page-title {
    color: #0B7702 !important;
}

.metismenu .g_heading {
    color: #0B7702 !important;
}

.metismenu ul a {
    color: #0B7702 !important;
}

.btn-round,
.btn-primary {
    background-color: #0B7702 !important;
    color: white !important;
    float: right;
    width: 140px;
    height: 50px;
    font-size: 18px;
    font-weight: 500;
}

.subject_primary {
    background-color: #d7eaff !important;
    color: #0B7702 !important;
    height: 31px;
    font-size: 11px;
    font-weight: 500;
    padding: 0px 5px;
}

.login_btn {
    background-color: #0B7702 !important;
    color: white !important;
    float: right;
    font-size: 18px;
    font-weight: 500;
}

.btn-round,
.btn-primary,
.btn-secondary:focus {
    box-shadow: none;
}

.form-control::placeholder {
    font-size: 15px !important;
}

.form-control {
    height: 40px;
    border: 1px solid #868686;
    font-size: 18px !important;
}

.form-control:focus {
    box-shadow: 0 0 0 0.2rem rgb(165 208 255) !important;
    border-color: #a5d0ff !important;
}

.page .section-white,
.page .section-body {
    background-color: #F0FBD0 !important;
    position: relative !important;
}

.card-header .card-title {
    font-weight: 600 !important;
}

.feeds_widget li:hover .title a {
    color: white;
}

/* .page-header .right .notification .dropdown a:hover{
    background-color: #a5d0ff !important;
} */
.dropdown-menu .dropdown-item:focus {
    background-color: #0B7702 !important;
    color: #fff !important;

}


.dropdown-menu:hover .dropdown-item {
    color: #fff !important;
}

/* .dropdown-menu-arrow .dropdown-item:hover i{
    color: red !important;
} */
/* .dropdown-menu-right .dropdown-item:hover{
    color: white;
} */
.dropdown-menu .dropdown-item.readall {
    padding: 10px !important;
}

.dropdown-menu .dropdown-item.readall:hover {
    color: white !important;
    padding: 10px !important;
}

.feeds_widget li:hover i {
    color: white;
}

.userlist_view {
    text-align: center !important;
}

/* .ag-cell div {
    display: flex;
    justify-content: center;
} */

.card-title {
    font-size: 18px;
    color: #0B7702;
    font-weight: 500 !important;
    text-align: left !important;
}

.card-title i {
    font-size: 23px;
    color: #0B7702;
}

.custom-top-search {
    background-color: #0B7702 !important;
}

.custom-top-search .fa-search:before {
    color: white !important;
}

.custom-input {
    width: 60% !important;
}

.custom-footer {
    font-size: 18px;
    color: #0B7702;
    text-align: center;
}

.viewmore_icon::before {
    color: white !important;
    font-size: 17px !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: none !important;
}

.table td,
.table th {
    vertical-align: middle;
}

.table thead th {
    color: #0B7702;
    font-weight: 800 !important;
    font-size: 15px !important;
    vertical-align: middle;
}

.table thead td {
    vertical-align: middle;
}

.table.table-hover tbody tr:hover {
    background: none !important;
}

.table td,
.table th {
    font-size: 16px !important;
}

.custom-sidebar-logo {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50% !important;
}

.table> :not(:first-child) {
    border-top: 1.1px solid #0B7702 !important;
}

.page-header .right .nav-pills .dropdown {
    background: #0B7702 !important;
}

.ag-center-cols-clipper a {
    color: #0B7702 !important;
}

.ag-center-cols-clipper .btn-icon i {
    color: #0B7702 !important;
}

/* #Add,
.btn-primary {
    height: 45px !important;
    width: 100px !important;
    border-radius:14px;
} */

.ag-header-viewport {
    background-color: white !important;
}

.ag-header-container {
    font-size: 16px !important;
}

.ag-center-cols-container {
    font-size: 13px;
}

.ag-header-cell-text {
    color: #0B7702 !important;
}

.fa-file-text-o:before {
    color: #0B7702 !important;
}

.fa-edit:before {
    color: #0B7702;
}

/* 
.fa-print:before {
    color: #0B7702;
} */

::selection {
    color: white !important;
    background-color: #0B7702 !important;
}

.auth .auth_left {
    background-color: #0B7702 !important;
}

.auth {
    background-color: #a5d0ff !important;
}

.form-label .small {
    color: #0B7702 !important;
}

.auth .header-brand {
    color: #0B7702 !important;
}

.nav-tabs .nav-link.active {
    border-color: #0B7702 !important;
    color: #0B7702 !important;
}

#header_top .brand-logo {
    color: #0B7702 !important;
}

.card-title i {
    font-size: 17px !important;
}

.ag-paging-panel {
    font-size: 17px !important;
    color: #0B7702 !important;
}

.ag-icon {
    font-size: 17px !important;
    color: #0B7702 !important;
}

/* .tag-success,
.tag-green {
    background: none;
    border: 2px solid #cedd7a;
    color: #cedd7a;
    font-weight: 800;
    min-width: 68px;
    justify-content: center;
    margin-top: 5px;
} */

.user_status {
    padding: 3px 10px;
}

/* #Departments-list .ag-header-cell-label {
    display: flex;
    justify-content: center;
} */

#Departments-list .ag-theme-balham .ag-cell,
.ag-theme-balham .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    display: flex;
    justify-content: center;
}

/* .tag-danger {
    background: none;
    border: 2px solid #e8769f;
    color: #e8769f;
    font-weight: 800;
    margin-top: 5px;
} */

/* .hotel_sec .tag-danger {
    background: none;
    border: 2px solid #e8769f;
    color: #e8769f;
    font-weight: 800;
    margin-top: 5px;
} */

.page-header .right .nav-item .dropdown-toggle {
    padding: 10px 25px;
}

.page-header .right .nav-item .dropdown-toggle i {
    margin: 0px 10px !important;
}

.dropdown-menu .dropdown-item a {
    background-color: #0B7702 !important;
    color: #0B7702 !important;
}

.dropdown-menu .dropdown-item {
    color: #0B7702 !important;

}

.dropdown-menu:hover .dropdown-item {
    color: #0B7702 !important;
}

.dropdown-menu .dropdown-item:hover {
    background-color: #0B7702 !important;
    color: white !important;
}

.avatar {
    background-color: none !important;
}

.auth_right .carousel-indicators {
    bottom: -50px;
}

.auth_right .carousel-indicators button {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 0px 5px;
    margin-top: 30px;
}

.auth_right .carousel-control-prev {
    display: none;
}

.list-unstyled {
    height: 300px;
    overflow: auto;
}

.list-unstyled-data-null {
    height: 50px;
    overflow: auto;
    position: relative;
    left: -9px;
    top: 20px;
}

/* .list-unstyled ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
} */

.carousel-inner {
    height: 518px;
}

.carousel-item {
    width: 100%;
    height: 400px;
}

.carousel-item img {
    width: 100%;
    height: 500px;
}

.carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.tab_bg {
    background-color: #fff;
}

.tab_bg .nav-tabs .nav-link {
    width: 220px;
    background: #d7eaff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 20px;
    font-weight: 500;
    color: #0B7702;
    position: relative;
    margin-bottom: 30px;
    padding: 10px;
}

.metismenu {
    margin-bottom: 40px;
}

.tab_bg .nav-tabs {
    border-bottom: 0px !important;
}

.tab_bg .nav-tabs .nav-link.active {
    background-color: #0B7702 !important;
    color: #fff !important;
    border-bottom-left-radius: 25px;
    border-top-right-radius: 25px;
    border-top-left-radius: 0px;
    transform: translate3d(0px);
    transition: 0.3s;
    width: 220px;
}

.tab_bg .nav-tabs .nav-link.active:after {
    content: "";
    position: absolute;
    bottom: -35px;
    border: 15px solid transparent;
    border-top-color: #0B7702;
}

.tab_bg .nav-tabs {
    margin-bottom: 10px !important;
}

.top-floor {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.top-floor .floor {
    width: 74px;
    height: 100px;
    border: 3px solid #0B7702;
    position: relative;
    margin-right: 5px;
    line-height: 1.2;
    margin-bottom: 10px;
    border-radius: 8px;
}

.top-floor .floor .room_no {
    background: #0B7702;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    display: block;
    /* width: 30px; */
    text-align: center;
    position: absolute;
    top: -1px;
    left: -1px;
    padding: 3px 8px;
    /* border-bottom-left-radius: 10px; */
    /* border-top-right-radius: 10px; */
    border-bottom-right-radius: 10px;

    /* background: orange;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    display: block;
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 3px 8px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px; */
}

.top-floor .floor p {
    margin-bottom: 0px;
    top: 45px;
    display: flex;
    height: 100px;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    font-size: 15px;
    font-weight: 600;
}

.color_name {
    display: flex;
    margin-top: 20px;
}

.color_name .b_t {
    display: flex;
    margin-right: 20px;
}

.color_name .b_t .box {
    width: 25px;
    height: 25px;
}

.color_name .b_t p {
    font-size: 15px;
    font-weight: 600;
    margin-left: 10px;
    text-transform: uppercase;
    color: #0B7702;
}

.dt_hr_min {
    width: 170px;
}

.tick {
    position: absolute;
    left: 17px !important;
    top: 50px !important;
    background: green !important;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 5px 10px;
    color: #fff;
}

.vertical-center button {
    background-color: #0B7702 !important;
    color: white !important;
    float: right;
    width: 140px;
    height: 50px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 10px !important;
}

.vertical-center button {
    width: max-content;
}

.allocate_text {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ccc;
    color: #000;
    font-size: 12px;
    font-weight: 500;
    /* display: block; */
    text-align: center;
    position: absolute;
    top: 37px;
    left: -0.5px;
    padding: 0px 7px;
    border-radius: 0px;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 68px;
    height: 37px;
    /* display: -webkit-box; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.hotel_details .left_question {
    font-weight: bold;
    text-transform: capitalize;
}

.hotel_details .left_question span {
    float: right;
}

.top_img_details {
    display: flex;
    justify-content: center;
    border-right: 1px solid#0B7702 !important;
}

.qrtop_img_details {
    display: flex;
    justify-content: center;
}

.top_img_details .img_size,
.qrtop_img_details .img_size {
    width: 130px;
    height: 130px;
    margin-right: 20px;
}

.top_img_details .img_size img,
.qrtop_img_details .img_size img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.hot_img_size {
    width: 100%;
    height: 400px;
}

.hot_img_size img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.react-multi-carousel-item {
    padding: 0px 10px;
}

.react-multiple-carousel__arrow {
    background-color: #0B7702 !important;
}

.react-multiple-carousel__arrow:hover {
    background-color: #0B7702 !important;
}

.log_add_check {
    padding: 10px;
    border: 1px solid #ccc;
    display: flex;
    margin-bottom: 15px;
}

.log_add_check .logo {
    width: 20%;
    display: flex;
    align-items: center;
}

.log_add_check .logo .img_size {
    width: 80px;
    height: 80px;
    border: 1px solid#f1f1f1;
    border-radius: 50%;
}

.log_add_check .logo .img_size img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.log_add_check .address {
    width: 60%;
}

.log_add_check .address .h_name {
    font-weight: 500;
    color: #0B7702;
}

.log_add_check .ch_box {
    width: 20%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
}

.log_add_check .ch_box .form-check .form-check-input {
    float: left;
    margin-left: 0px !important;
    width: 25px !important;
    height: 25px !important;
    margin-top: 15px !important;
}

/* .ag-theme-alpine .ag-ltr .ag-cell {
    text-align: center !important;
} */

.logoForm {
    display: flex;
    justify-content: center;
}

.logoform_profile {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto;
    padding: 0px;
}

.logoform_profile img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.logoform_profile i {
    position: absolute;
    bottom: -15px;
    background-color: white;
    cursor: pointer;
    padding: 6px;
    border-radius: 50%;
    font-size: 20px;
    right: 33px;
}

.upload-btn-wrapper {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.upload-btn-wrapper input[type="file"] {
    font-size: 9px;
    position: absolute;
    left: -85px;
    top: 88px;
    opacity: 0;
    cursor: pointer !important;
}

.img_remove {
    right: 15px;
    position: absolute;
    top: 5px;
    font-size: 22px;
    color: #0B7702;
    background-color: #fff;
    cursor: pointer;
}

.photos_wid {
    flex-wrap: wrap;
    display: flex;
}

.form-control {
    border: 1px solid #868686 !important;
}

/* .css-1s2u09g-control {
    border: 1px solid #868686 !important;
} */

.cke_chrome {
    border: 1px solid #868686 !important;
}

.limit_size {
    /* width: 100px !important; */
    /* margin-right: 10px; */
}

.custom-checkbox .custom-control-label:before {
    border: 1px solid #868686 !important;
    width: 22px !important;
    height: 22px !important;
    margin-top: -4px;
    margin-left: -5px;
}

.custom-checkbox .custom-control-label:after {
    top: -1px !important;
    left: -28px !important;
    width: 20px !important;
    height: 19px !important;
}

.room-type {
    width: 600px !important;
}

.modal-title {
    color: #0B7702 !important;
}

.listnotify .modal-backdrop.show {
    opacity: 0 !important;
}

.country_model .form-group .flag_code_sty {
    margin-top: 28px;
    height: 37px !important;
    width: 60px !important;
}

.css-2613qy-menu input {
    background-color: #fff !important;
}

.feeds_widget li:hover small {
    color: #fff !important;
}

/* .code_align {
    position: absolute !important;
    top: 8px;
    right: 14px;
} */
.spinner_load_ow_side {
    position: absolute !important;
    top: 100% !important;
    left: 45% !important;
    margin-top: 295px;
    height: 80px;
    width: 80px;
}

.spinner_load_user {
    position: absolute !important;
    top: 100% !important;
    left: 45% !important;
    margin-top: 295px;
    height: 80px;
    width: 80px;
}

.spinner_load_viewtic {
    position: absolute !important;
    top: 100% !important;
    left: 45% !important;
    margin-top: 295px;
    height: 80px;
    width: 80px;
}

/* ----------------- comment center loader -------------- */
.spinner_load {
    position: absolute !important;
    top: 75% !important;
    left: 605px !important;
    margin-top: 280px;
    height: 80px;
    width: 80px;
    z-index: 999;
}

.loader_img_style {
    position: absolute;
    top: 295%;
    left: 610px;
    border-radius: 50%;
    width: 70px;
    z-index: 999;
    height: 70px;
}

/* ------------------------- */
.adjustImage {
    top: 318% !important;
}

.loader_img_overAll {
    position: absolute;
    top: 430%;
    left: 591px;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    z-index: 999;
}

/* --------------- common loader start-- ag-grid --------- */
.table-responsive {
    position: relative;
}

.common_loader_ag_grid {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.loader_img_style_common_ag_grid {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    padding: 8px;
    position: absolute;
}

.spinner_load_common_ag_grid {
    height: 80px;
    width: 80px;
}

/* --------------- common loader end ------------- */
/* --------------- common loader in view & edit common page ------------- */
.page {
    height: 100vh;
}

.common_loader {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.loader_img_style_common {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    padding: 8px;
    position: absolute;
}

.spinner_load_common {
    height: 80px;
    width: 80px;
}

/* ------------- common loader in view & edit common page end  ---------- */

/* -------------- model spinner start ------------- */
.model_loader_poss {
    z-index: 9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loader_img_style_model {
    height: 100px;
    width: 400px;
    border-radius: 50%;
    padding: 8px;
    position: absolute;

}

.spinner_load_model {
    z-index: 999;
    height: 80px;
    width: 120px;
}

/* -------------- model spinner end --------------- */

.loader_img_style_dsh {
    position: absolute !important;
    top: 60px !important;
    left: 580px !important;
    margin-top: 250px;
    height: 80px;
    width: 80px;
    z-index: 999;
    border-radius: 50%;
    padding: 8px;
}

.spinner_load_dash {
    position: absolute !important;
    top: 60px !important;
    left: 580px !important;
    margin-top: 250px;
    z-index: 999;
    height: 80px;
    width: 80px;
}

/* ------------pr nx ----------------- */
.spinner_load_prenx {
    position: absolute !important;
    top: 60px !important;
    left: 580px !important;
    margin-top: 90px;
    z-index: 999;
    height: 80px;
    width: 80px;
}

.loader_img_style_prenx {
    position: absolute !important;
    top: 60px !important;
    left: 580px !important;
    margin-top: 90px;
    height: 80px;
    width: 80px;
    z-index: 999;
    border-radius: 50%;
    padding: 8px;
}

/* ------------------------------ */

.loader_img_style_his {
    position: absolute !important;
    top: 0px !important;
    left: 580px !important;
    margin-top: 150px;
    height: 80px;
    width: 80px;
    z-index: 999;
    border-radius: 50%;
    padding: 8px;
}

.spinner_load_his {
    position: absolute !important;
    top: 0px !important;
    left: 580px !important;
    margin-top: 150px;
    z-index: 999;
    height: 80px;
    width: 80px;
}

.loader_img_style_soc {
    position: absolute !important;
    top: 200px !important;
    left: 605px !important;
    margin-top: 250px;
    height: 80px;
    width: 80px;
    z-index: 999;
    border-radius: 50%;
    padding: 8px;
}

.spinner_load_soc {
    position: absolute !important;
    top: 200px !important;
    left: 605px !important;
    margin-top: 250px;
    z-index: 999;
    height: 80px;
    width: 80px;
}

.sign_out {
    background-color: #0B7702 !important;
    font-weight: 500;
    color: #fff !important;
}

.alignment-flag {
    vertical-align: baseline !important;
}

.norecord {
    display: flex;
    flex-direction: column !important;
    margin-top: 100px !important;
}

.status_style {
    display: flex !important;
    justify-content: center !important;
}

.flag_center {
    display: flex;
    justify-content: center;
    height: 35px;
    align-items: center;
}

/* ===========previos next style ============= */

.page-item.active .page-link {
    background-color: #0B7702;
    border-color: #0B7702;
    color: #fff !important;
}

.page-item.active .page-link:hover {
    color: #fff !important;
}

.page-link {
    color: #0B7702 !important;
}

.rooms_type_in_color .color_box {
    width: 18px;
    height: 18px;
    display: inline-block;
}

.rooms_type_in_color .type_name {
    font-size: 15px;
    font-weight: 500;
    position: relative;
    bottom: 4px;
    margin-left: 2px;
}

.right_chat {
    border-top: 3px solid #ccc;
}

.comment_box {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
}

.dashboard-hotel {
    width: 500px;
    height: 45pxf;
}

.css-26l3qy-menu:hover {
    color: black !important;
}

.css-26l3qy-menu {
    color: black !important;
}

.read_more {
    font-weight: 500;
    font-size: 14px;
    color: rgb(0, 123, 255);
    text-transform: capitalize;
    cursor: pointer;
    color: #0B7702;
}

.read_more span {
    color: #0B7702;
    font-weight: 800;
    text-transform: capitalize;
}

.dashboardIcon {
    position: relative;
    top: 2px;
}

.view_model_size .modal-dialog {
    max-width: 64% !important;
}

/* .category_prev_list{
    display: flex;
    justify-content: space-between;
} */
.mainten_name h6 {
    font-size: 20px;
}

.category_previleges h5 {
    font-weight: 600;
}

.category_access ul {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
}

.category_access li {
    display: inline-block;
    padding: 0px 20px;
}

.category_access .tag {
    padding: 0px 25px;
    font-size: 15px;
}

.view_model_size .modal-header .close {
    font-size: 35px !important;
    padding: 0rem 1rem !important;
}

.viewtask {
    left: -29px !important;
}

/* .csv_model .modal-dialog {
    max-width: 90% !important;
    width: 100% !important;
} */
.info-modal .modal-content,
.info-modal .modal-dialog {
    height: 630px !important;
    overflow: auto !important;
}

.table_head_top {
    height: 530px !important;
    white-space: nowrap;
    overflow: auto;
}

.modal.show .modal-dialog {
    max-width: 40%;
    transform: none;
}

.modal-width {
    width: 70% !important;
}

.User_part .ribbon-box {
    position: absolute !important;
    left: -16px !important;
}

#overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    /* top: 0;
    left: 0;
    right: 0; */
    bottom: 0;
    background-color: rgb(255, 255, 255, 0.5);
    /* background-color: rgba(0, 0, 0, 0.5); */
    z-index: 2;
}

#overlay-model {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(255, 255, 255, 0.5);
    /* background-color: F0FBD0 */
    z-index: 2;
}

.siteNameAdmin {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mandatory {
    display: flex;
    justify-content: space-between;
}

.mandatory-label {
    margin-top: 12px;
}

.css-6j8wv5-Input {
    margin: 3.34px !important;
}

.history .my_sort_cut {
    font-size: 20px !important;
    transition: none !important;
}

.my_sort_cut_1 {
    font-size: 20px !important;
    transition: none !important;
}

.history .my_sort_cut:hover i {
    font-size: 30px !important;
}

.my_sort_cut:hover .dash_img {
    width: 25px !important;
    height: auto !important;
    -webkit-transition: font-size 0.2s;
    -moz-transition: font-size 0.2s;
    -ms-transition: font-size 0.2s;
    -o-transition: font-size 0.2s;
    transition: font-size 0.2s;
}

.my_sort_cut .dash_img {
    width: 30px;
    height: auto;
    display: inline-block;
    margin: auto;
    padding: 2px;
}

.my_sort_cut .dash_img img {
    width: 100% !important;
    height: 100% !important;
}

.list_ticket .my_sort_cut {
    padding-top: 10px !important;
    font-weight: 500;
    color: #0B7702;
    position: relative;
}

.dash_text {
    margin-top: 10px;
    color: #444444;
}

.list_ticket .tag {
    font-weight: 500 !important;
    width: 92px !important;
    height: 36px !important;
    font-size: 16px !important;
}

/* .history .my_sort_cut span {
    font-size: 20px !important;
} */
.all_search_image_size {
    height: 34px !important;
    width: 34px !important;
}

.all_search_text_size {
    position: relative;
    top: 2px;
    margin-left: 5px;
}

.metismenu ul a {
    padding: 5px 5px 5px 30px;
}

.generate-button {
    padding: 1px;
    margin-top: 27px;
    margin-right: 0px;
    width: 138px !important;
    font-size: 13px;
}

.generate-icon {
    color: "red" !important;
    font-size: 20px !important;
}

.assign {
    background-color: #d7eaff !important;
    border-radius: 10px;
    padding: 20px;
}

.total_count {
    padding: 30px 0px 0px 10px !important;
}

.total_count_Cash_Count {
    padding: 10px 0px 0px 0px !important;
}

.total_count_Cash_Count_view {
    padding: 0px 0px 0px 0px !important;
}

/* .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 30px;
    cursor: pointer;
    height: 100px;
    overflow: scroll;
    top: -5px;

}

.fc-daygrid-event-harness {
    width: 150px;
    margin: auto;
}

.fc-event-future p {
    margin-bottom: 0px;
    padding: 5px;
    font-size: 16px;
    white-space: nowrap;
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.font-18 {
    font-size: 18px;
    text-align: justify;
    height: 160px;
    overflow: auto;
}

.fc .fc-button-primary {
    background-color: #0B7702 !important;
} */

.fc-event-future p {
    margin-bottom: 0px;
    padding: 5px;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* .fc .fc-button-primary {
    background-color: #0B7702 !important;
} */

.fc-theme-standard .fc-popover {
    z-index: 1 !important;
}

.calendar-input-icon {
    font-size: 16px;
    padding-top: 1px;
    padding-right: 8px;
}

.fc-event-main p {
    margin-bottom: 0px;
    padding: 5px;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lightBoxModal>div {
    z-index: 200;
}

/* .vedio_play_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #0B7702;
} */
.play_is {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.vedio_play_icon {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #0B7702;
}

.vedio_play_icon i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
}

.vedio_overlay {
    position: absolute;
    width: 100%;
    height: 200px;
    top: 0px;
    cursor: pointer;
    z-index: 2;
}

.vedio_bg {
    /* border: 1px solid #0B7702; */
    /* border-radius: 10px; */
    height: 200px;
    width: 100%;
    position: relative;
}

.box-dark {
    color: black !important;
    border: 1px solid #495057;
}

.selectgroup-button {
    border: 1px solid #868686;
    font-weight: bold;
}

.fc .fc-button {
    padding: 5px !important;
}

.clear-amount {
    border-radius: 60px;
    height: 25px !important;
    width: 25px !important;
    background: #ec6161 !important;
    margin-top: 4px;
}

.clear-amount i {
    position: relative;
    right: 8.3px;
    top: -5.2px;
}

.label-amount {
    cursor: pointer;
    padding-right: 10px;
    font-size: 21px;
}

.negative-value {
    color: red !important;
}

.form-group .radio-button {
    cursor: pointer;
    height: 20px;
    width: 20px;
    margin-top: 10px;
}

.radio-button-label {
    cursor: pointer;
    position: relative;
    top: -4px !important;
}

.send-review-bread {
    position: relative;
    right: 40px;
}

/* ===================== Action icons ============== */
.ag-cell-focus,
.ag-cell-no-focus {
    border: none !important;
}

.ag-cell:focus {
    border: none !important;
    outline: none !important;
}

.ag-header-cell-text {
    font-size: 17px;
}

.ag-pivot-on .ag-header-group-cell {
    font-size: 25px;
}

.ag-cell .tag {
    margin-top: 25px !important;
    margin: auto;
}

.ag-cell-wrap-text {
    word-break: break-word;
}

.logo_center_fix {
    display: flex;
    height: 80px;
    justify-content: center;
    align-items: center;
}

.user_cus_def {
    margin-top: 24px !important;
    margin: auto;
    display: flex;
    justify-content: center;
}

.inspection-label {
    display: flex;
    align-items: center;
}

/* .elips_to_hotel {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
} */

.login_btn {
    background-color: #0B7702 !important;
    color: white !important;
    float: right;
    font-size: 18px;
    font-weight: 500;
}

.pass_forgot {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.log_checkbox {
    width: 18px;
    height: 18px;
}

.log_icon {
    font-size: 20px !important;
}

.foreignObject {
    height: 300px !important;
}

.ag-theme-alpine .ag-ltr .ag-cell {
    border: none !important;
    /* padding: 20px 0px !important; */
    line-height: 85px !important;
    vertical-align: middle;
}

.modal_ok {
    height: 34px !important;
    width: 90px !important;
    border-radius: 7px !important;
    font-size: 15px;
    background-color: #0B7702 !important;
    color: white !important;
    font-weight: 500;
    padding: 0px !important;
    font-weight: bold;
}

.modal_cancel {
    height: 34px !important;
    width: 90px !important;
    border-radius: 7px !important;
    font-size: 15px;
    background-color: white !important;
    color: #0B7702;
    margin: 0px 10px;
    border: 1px solid #6e6e6e;
    font-weight: 500;
    padding: 0px !important;
    border: 1px solid #6e6e6e !important;
    color: #0B7702 !important;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    font-weight: bold;
}

.ag-paging-panel {
    font-weight: bold !important;
}

.card-header {
    padding: 20px;
}

.input:disabled {
    background-color: red !important;
}

.hd_bg_tic_rep {
    background-color: #fff !important;
}

.country_ctr {
    position: relative;
    top: 5px;
}

.chart {
    height: "auto";
}

.scrool_top_button {
    position: fixed;
    font-size: 20px;
    bottom: 10px;
    right: 5px;
    color: #fff;
    text-align: center;
}

.scrool_top_button .top_icon {
    background-color: white;
    color: #fff;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 16px;
    border: 1px solid #0B7702;
}

.scrool_top_button .top_icon:hover {
    border: 1px solid #0B7702;
    background: #fff;
    color: #0B7702;
}

.assign_icon:before {
    color: white !important;
    padding: 0px 5px;
    font-size: 18px;
}

.my_sort_cut:hover .dash_bar {
    position: absolute;
    top: 6px;
    left: 7px;
}

/* ---------------------------barcode hover start */
.barcode-container {
    position: relative;
    width: 100%;
    max-width: 400px;
}

.barcode-overlay {
    position: absolute;
    display: flex;
    height: 65px;
    width: 65px;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: 0.3s ease;
    background-color: #66000000;
}

.barcode-container:hover .barcode-overlay {
    background-color: black;
    opacity: 70%;
}

.barcode-icon {
    color: white;
    font-size: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.barcode-overlay .fa-download {
    color: #eee;
}

/* ---------------------------barcode hover end */

/* ============================= */
.badge {
    font-size: 16px;
}

.upcommcomp_btn {
    display: flex;
}

.upcommcomp_btn button {
    padding: 10px 35px;
    /* background: #d7eaff !important; */
    /* color: #0B7702; */
    font-size: 18px;
    font-weight: 600;
    margin-right: 10px;
}

.dashborad_Room_rate {
    position: relative !important;
    margin: 8px 0px !important;
}

.dashborad_Room_rate button {
    padding: 10px 35px;
    font-size: 15px;
    font-weight: 600;
    margin-right: 10px;
}

/* .upcommcomp_btn button:hover {
    color: #0B7702;
} */

.upcommcomp_btn button:focus {
    box-shadow: none;
}

.after_click {
    background-color: #0B7702;
    color: #fff;
    position: relative;
}

.after_click:hover {
    color: #fff;
}

.before_click {
    padding: 10px 20px;
    background: #d7eaff !important;
    color: #0B7702;
}

.before_click:hover {
    color: #0B7702;
}

/* =============print csss =============== */
.common_print_pageg .title_print_btn {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0B7702;
}

.common_print_pageg .border_out {
    background: #fff;
    padding: 20px;
}

.common_print_pageg .border_out h5 {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #0B7702;
    font-weight: 500 !important;
    text-transform: uppercase;
}

.common_print {
    background-color: #0B7702 !important;
    color: white !important;
    /* width: 140px; */
    height: 45px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 10px !important;
}

.common_print_pageg .ajenta_img {
    background: #d7eaff;
    margin: 20px 0px;
}

.common_print_pageg .ajenta_img .hotel_details .img_size {
    width: 150px;
    height: 150px;
    margin: 0 auto;
}

.common_print_pageg .ajenta_img .hotel_details .img_size img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #0B7702;
}

.common_print_pageg .ajenta_img .hotel_details h5 {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.common_print_pageg .top_content .left {
    font-size: 19px;
    font-weight: 600;
    margin-right: 5px;
}

.common_print_pageg .board_members {
    padding-bottom: 10px;
    border-bottom: 1px solid #0B7702;
    margin-bottom: 20px;
}

.common_print_pageg .list_data {
    margin: 20px 0px;
}

.site_pass .rmdp-container {
    width: 100%;
}

/* .common_print_pageg .list_data thead tr
{
    background: #0B7702 !important;
    color: #fff !important;

} */

/* ======================= */
.view_completed .logo_text {
    background-color: #d7eaff;
    padding: 20px;
    /* border-radius: 10px; */
}

.view_completed .logo_text .img_size {
    width: 250px;
    height: 250px;
    margin: 0 auto;
}

.view_completed .logo_text .img_size img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.view_completed .logo_text .border_color {
    border-right: 1px solid #0B7702;
}

.rmdp-input {
    border: 1px solid #868686 !important;
    border-radius: 5px;
    height: 39px !important;
    margin: 1px 0;
    padding: 2px 5px;
    width: 100%;
}

.icon-tabler-calendar-event {
    right: 8.5px !important;
}

.time_zone_input {
    position: relative;
}

.time_zone_input .fa::before {
    position: absolute;
    right: 10px;
    top: 10px;
}

.view_completed .icon_test i {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    color: #0B7702;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}

.view_completed .icon_test p {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 500;
}

.view_completed .icon_text_space {
    padding: 0px 50px;
}

.view_completed .icon_text_space .ajenta {
    margin-top: 40px;
}

.view_completed .icon_text_space .ajenta p {
    font-size: 30px;
    color: #0B7702;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
}

.view_completed .logo_text .ht_name {
    font-size: 23px;
    font-weight: 20px;
    font-weight: 500;
    text-transform: capitalize;
    margin-top: 20px;
    color: #0B7702;
    text-align: center;
}

.table_title {
    margin: 40px 0px 0px 0px;
}

.table_title .title {
    font-size: 30px;
    color: #0B7702;
}

.common_print_nav {
    background-color: #0B7702;
    padding: 20px;
    display: none;
}

.common_print_nav .img_size {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.common_print_nav .img_size img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.common_footer_print {
    background: #0B7702;
    padding: 20px;
    display: none;
}

.common_footer_print p {
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.common_footer_print p a {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-right: 10px;
    margin-left: 10px;
    text-decoration: none;
}

/* ============== print ===================== */

@media print {
    @page {
        /* size: A4; */
        /* DIN A4 standard, Europe  */
        margin-top: 35px !important;
        margin-bottom: 35px !important;
        /* margin: 2cm */
    }

    .upcoming_table_mom {
        margin-top: 190px;
    }

    .print_img {
        display: none;
        white-space: normal;
    }

    tbody,
    td,
    tfoot,
    tr {
        height: auto !important;
    }

    .category_space {
        margin: 0px !important;
    }

    .description_space {
        margin: 0px !important;
    }

    .alignment_fix_momcom_forecord .card {
        height: auto !important;
    }

    body *:not(#print_page):not(#print_page *) {
        visibility: hidden;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
        /* size:8.5in 11in; */
        /* margin: 25mm 25mm 25mm 25mm !important;
        
        size: auto; */

        /* margin: 10mm 0 10mm 0; */
        /* margin: 2.5cm 0; */
        /* size: A4; */
    }

    #print_page {
        visibility: visible !important;
    }

    .card-print {
        padding-left: 20px;
        padding-right: 20px;
    }

    #print_page .alignment_fix_momcom {
        position: absolute !important;
        top: 0 !important;
        left: -278px !important;
        width: 140% !important;
        overflow: auto !important;
    }

    #print_page .alignment_fix_momcom_forecord {
        position: absolute !important;
        top: -150px !important;
        left: -278px !important;
        width: 140% !important;
        overflow: auto !important;
    }

    #print_page .border_color {
        border-right: 0px;
    }

    #print_page .common_print_nav,
    #print_page .common_footer_print {
        display: block;
    }

    /* ----------------- room inspectio ---------------- */
    #print_page .alignment_fix_roomins {
        position: absolute !important;
        top: 0 !important;
        left: -278px !important;
        width: 140% !important;
        overflow: auto !important;
        margin-top: -140px;
    }

    #print_page .alignment_fix_public {
        position: absolute !important;
        top: 0 !important;
        left: -278px !important;
        width: 140% !important;
        overflow: auto !important;
        padding: 20px;
    }

    .show_data {
        display: block !important;
        visibility: visible;
    }
}

.side_barder_color tr td {
    border: 1px solid #e8e9e9 !important;
    text-align: center !important;
}

.box_room_rate {
    border: 1px solid #bec0c0 !important;
    padding: 15px !important;
    margin: 8px !important;
}

.print-header {
    background-color: #F3FDE8 !important;
    border-radius: 10px 10px 0px 0px;
    height: 70px;
    font-size: 18px;
    font-weight: bold;
    padding-top: 25px;
    padding-left: 10px;
}

/* ===================print_page_room =============== */
.badge-light {
    color: white;
    margin-top: 10px;
    position: absolute;
    right: 11px;
    top: -9px;
}

.badge_light_Room_type {
    color: white;
    margin-top: 10px;
    right: 11px;
    top: -9px;
    font-size: 13px;
}

.hiddenRow {
    padding: 0 !important;
}

.removeTask_icon {
    background-color: red;
    width: 30px;
    height: 30px;
    display: inline-block;
    float: right;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 50%;
    cursor: pointer;
}

.removeTask_icon i {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.css-13cymwt-control,
.css-13cymwt-control:hover {
    border-color: 1px solid #868686 !important;
    border: 1px solid #868686 !important;
}

.selectgroup-button {
    height: 40px;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    /* color: hsl(0, 0%, 20%); */
    display: flex;
    justify-content: center;
    align-items: center;
}

.first_month {
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
}

.last_month {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
}

.top_img_guestdetails {
    display: flex;
    justify-content: center;
}

.top_img_guestdetails .img_size,
.qrtop_img_details .img_size {
    width: 130px;
    height: 130px;
    margin-right: 20px;
}

.top_img_guestdetails .img_size img,
.qrtop_img_details .img_size img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.message_detail {
    /* display: flex; */
    border-right: 1px solid#0B7702 !important;
}

.chat_windows .my-message p {
    border-radius: 60px 60px 0 60px;
}

.chat_windows .my-message {
    list-style: none;
}

.chat_windows .message p {
    width: max-content;
    width: 650px;
    display: flex;
    float: right;
    padding: 11px;
    padding-left: 24px;
    margin-bottom: 11px;
}

.chat_windows .message span {
    position: absolute;
    bottom: -8px;
    right: 0;
    display: block;
    font-size: 11px;
    font-weight: 600;
}

.message p {
    margin-bottom: 5px;
    font-style: italic;
}

/* .chat_windows .message .time{
    font-size: 12px;
    display: flex;
    float: right;

} */
.bg-light-gray {
    background-color: #e9f3fe;
    color: #0B7702 !important;
}

.Icon-inside {
    position: relative;
}

.Icon-inside i {
    position: absolute;
    right: 18px;
    top: 71px;
    padding: 10px 10px;
    color: #0B7702 !important;
    font-size: 28px;
}

.chat_app .chat_windows .message .time {
    display: block;
    vertical-align: middle;
}

.chat_windows .my-message .received {
    border-radius: 60px 60px 60px 0 !important;
}

.review_sec .input-group .btn {
    z-index: 0;
}

.review_sec .fback_body {
    box-shadow: 0px 0px 5px #ccc;
}

.chat_windows .message .received-time {
    position: absolute;
    left: -526px;
}

.review_sec .reply_icon {
    display: flex;
    margin-left: 10px;
}

.form-check-guest {
    position: relative;
    margin-top: 33px;
    display: block;
    padding-left: 1.25rem;
}

@media only screen and (max-width: 1080px) {
    .guest_nav_sec .navbar {
        justify-content: center;
    }
}

@media only screen and (max-width: 800px) {
    .guest_nav_sec .navbar .navbar-brand .guest_invite_img {
        width: auto;
    }

    .guest_nav_sec .navbar .guest_bf_logo {
        margin: auto;
    }

    .colan_guest {
        display: none;
    }
}

@media only screen and (max-width: 800px) {
    .guest_invite_footer .footer {
        flex-direction: column;
    }

    .guest_invite_footer .footer .footer-right {
        margin: auto;
    }

    .guest_invite_footer .footer-left {
        justify-content: center;
    }
}

.csv_log_setup {
    position: relative;
}

.csv_log_setup .img_size {
    width: 100px;
    height: 100px;
}

.csv_log_setup .img_size img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
}

.img_icon:hover .csv_overlay {
    position: absolute;
    top: 0px;
    background: rgba(0, 0, 0, 0.5);
    width: 100px;
    height: 100px;
    cursor: pointer;
    /* display: none; */
}

.img_icon:hover span {
    position: absolute;
    top: 35px;
    left: 35px;
    display: inline-block;
    cursor: pointer;
    /* display: none; */
}

.csv_log_setup .zoom_i {
    color: #fff;
    font-size: 35px;
    cursor: pointer;
}

.img_icon {
    width: 100px;
    height: 100px;
}

.img_icon:hover span,
.img_icon:hover .csv_overlay {
    display: block !important;
}

.bulk_msg_table td {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.bulk_msg_table th {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.modal-header .btn-close {
    margin-top: 4px !important;
}

.wrong_file_text {
    font-size: 20px;
    padding: 20px;
}

.wrong_file_text span {
    font-size: 22px;
    font-weight: 500;
    cursor: pointer;
}

.bulk_tab li {}

.bulk_tab li .nav-link {
    background-color: #d7eaff !important;
    color: #0B7702 !important;
    height: 45px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    border-radius: 10px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0px 15px !important;
}

.bulk_tab li .nav-link:hover {
    background-color: #0B7702 !important;
    color: #fff !important;
    height: 45px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    border-radius: 10px !important;
    border: none !important;
}

.bulk_tab .nav-link.active {
    border: none !important;
    background-color: #0B7702 !important;
    color: #fff !important;
    border-radius: 10px !important;
}

.break_here {
    page-break-before: always;
}

.social_rest_btn {
    background-color: #0B7702 !important;
    color: white !important;
    /* width: 140px; */
    height: 38px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px !important;
    margin-top: 7px;
}

.social_rest_btn i {
    font-size: 18px !important;
}

.check_box_adjustment {
    position: relative;
    right: 10px !important;
}

.topsocial_rest_btn {
    background-color: #0B7702 !important;
    color: white !important;
    height: 45px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px !important;
}

.edit_user_table {
    height: 600px;
    overflow: auto;
    border: 1px solid #dee2e6;
}

.edit_user_table_Custom {
    height: auto;
    overflow: auto;
    border: 1px solid #dee2e6;
}

.edit_user_table thead {
    position: sticky;
    top: -3px;
    z-index: 1;
    border-top: 2px solid #dee2e6;
}

.edit_user_table_cash_count {
    background-color: #F3FDE8 !important;
}

.edit_user_table_cash_count {
    height: 800px;
    overflow: auto;
    border: 1px solid #dee2e6;
}

.edit_user_table_cash_count thead {
    position: sticky;
    top: -3px;
    z-index: 1;
    border-top: 2px solid #dee2e6;
}

.edit_user_table_cash_count_auto {
    background-color: #F3FDE8 !important;
}

.edit_user_table_cash_count_auto {
    height: auto;
    overflow: auto;
    border: 1px solid #dee2e6;
}

.edit_user_table_cash_count_auto thead {
    position: sticky;
    top: -3px;
    z-index: 1;
    border-top: 2px solid #dee2e6;
}

.chart_collaps {}

.chart_collaps button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 14px !important;
    height: 45px !important;
    background-color: #0B7702 !important;
    color: white !important;
    font-size: 18px;
    font-weight: 500;
    width: 100%;
}

.chart_collaps button:focus {
    box-shadow: none;
}

.chart_collaps button i {
    font-size: 30px;
    font-weight: 800;
}

.chart_collaps .border_right {
    border-right: 2px solid #ccc;
}

.contain_image {
    object-fit: contain;
}

.viewtodolistitem_modal_body {
    background-color: #fff;
    border-radius: 20px;
}

.cc_phone_select {
    z-index: 9;
}

.hotel_select_edit {
    z-index: 10;
}

.rounded-circle {
    object-fit: contain;
}

.cc_phone_select {
    z-index: 9;
}

.hotel_select_edit {
    z-index: 10;
}

.click_gif_images {
    width: 60px;
    height: 60px;
    rotate: 11deg;
}

.click_gif_images img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.social_reviews_option {
    position: relative;
}

.click_gif_images {
    position: absolute;
    right: 50px;
    top: 17px;
}

.review_count {
    border: 1px solid #6e6e6e;
    outline: 1px solid #6e6e6e;
    color: #0B7702 !important;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    border-radius: 10px !important;
    /* padding: 0px 16px; */
    height: 45px !important;
}

.review_count:focus {
    box-shadow: none;
    border: 1px solid #6e6e6e;
    outline: 1px solid #6e6e6e;
}

@media only screen and (max-width: 1024px) {}

.elfplusbtn,
.elfminusbtn {
    width: 80px !important;
    height: 35px !important;
    margin-top: 15px;
}

@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 992px) {
    .menu_toggle {
        margin-bottom: 0px;
    }

    .hright {
        padding-top: 5px;
    }

    .upcommcomp_btn button {
        padding: 10px 23px;
        font-size: 11px;
    }

    .ag-theme-alpine .ag-paging-panel>* {
        margin: 0px !important;
    }

    .ag-paging-row-summary-panel {
        font-size: 15px !important;
    }
}

@media only screen and (max-width: 800px) {
    .menu_toggle {
        margin-bottom: 0px;
    }

    .hright {
        padding-top: 5px;
    }

    .upcommcomp_btn button {
        padding: 10px 23px;
        font-size: 11px;
    }

    .ag-theme-alpine .ag-paging-panel>* {
        margin: 0px !important;
    }

    .ag-paging-row-summary-panel {
        font-size: 15px !important;
    }
}

@media only screen and (max-width: 600px) {
    .menu_toggle {
        margin-bottom: 0px;
    }

    .hright {
        padding-top: 5px;
    }

    .upcommcomp_btn button {
        padding: 10px 23px;
        font-size: 11px;
    }

    .ag-theme-alpine .ag-paging-panel>* {
        margin: 0px !important;
    }

    .ag-paging-row-summary-panel {
        font-size: 15px !important;
    }
}

@media only screen and (max-width: 430px) {
    .menu_toggle {
        margin-bottom: 0px;
    }

    .hright {
        padding-top: 5px;
    }

    .upcommcomp_btn button {
        padding: 10px 23px;
        font-size: 11px;
    }

    .ag-theme-alpine .ag-paging-panel>* {
        margin: 0px !important;
    }

    .ag-paging-row-summary-panel {
        font-size: 15px !important;
    }
}

@media only screen and (max-width: 414px) {
    .menu_toggle {
        margin-bottom: 0px;
    }

    .hright {
        padding-top: 5px;
    }

    .upcommcomp_btn button {
        padding: 10px 23px;
        font-size: 11px;
    }

    .ag-theme-alpine .ag-paging-panel>* {
        margin: 0px !important;
    }

    .ag-paging-row-summary-panel {
        font-size: 15px !important;
    }
}

@media only screen and (max-width: 390px) {
    .menu_toggle {
        margin-bottom: 0px;
    }

    .hright {
        padding-top: 5px;
    }

    .upcommcomp_btn button {
        padding: 10px 23px;
        font-size: 11px;
    }

    .ag-theme-alpine .ag-paging-panel>* {
        margin: 0px !important;
    }

    .ag-paging-row-summary-panel {
        font-size: 15px !important;
    }
}

@media only screen and (max-width: 375px) {
    .menu_toggle {
        margin-bottom: 0px;
    }

    .hright {
        padding-top: 5px;
    }

    .upcommcomp_btn button {
        padding: 10px 23px;
        font-size: 11px;
    }

    .ag-theme-alpine .ag-paging-panel>* {
        margin: 0px !important;
    }

    .ag-paging-row-summary-panel {
        font-size: 15px !important;
    }
}

.time-style {
    border: 1px solid #868686 !important;
    padding-left: 10px !important;
    padding-top: 4px !important;
    width: -webkit-fill-available !important;
    height: 40px !important;
    border-radius: 12px !important;
    color: #495057 !important;
}

.MuiInput-underline:before {
    border: none !important;
}

.MuiInput-underline:after {
    border: none !important;
}

.MuiInputBase-input {
    cursor: pointer;
    color: #495057 !important;
}

/* .rmdp-calendar {
    height: max-content !important;
    padding: 4px;
    height: 290px;
    width: 555px;
}

.rmdp-week,
.rmdp-ym {
    display: flex;
    justify-content: space-between;
    height: 40px;
    width: 520px;
} */

.rmdp-panel-body span {
    font-size: 14px !important;
    line-height: 30px !important;
    margin: 0px 5px !important;
}

.rmdp-panel {
    min-width: 300px !important;
}

.rmdp-panel-body li,
.rmdp-panel-body li.bg-blue {
    background-color: #0B7702 !important;
    justify-content: center;
    width: 262px !important;
    margin-left: 15px !important;
    border-radius: 10px !important;
}

.rmdp-panel-body li .b-deselect {
    margin-right: 5px;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #0B7702 !important;
    box-shadow: 0 0 3px #28e3ff !important;
}

.MuiPickersToolbar-toolbar,
.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer {
    background-color: #0B7702 !important;
}

.MuiButton-text {
    color: #0B7702 !important;
}

.MuiPickersClockPointer-thumb {
    border: 14px solid #0B7702 !important;
}

.redirect_color {
    color: #0B7702;
    font-weight: 600;
    font-size: 18px;
}

.redirect_color:hover {
    text-decoration: underline;
}

.add_dynaminc_category .top_bott_border {
    border: 2px solid #0B7702;
}

.add_dynaminc_category .img_text_top {
    display: flex;
    justify-content: space-between;
    padding: 25px 0px;
}

.add_dynaminc_category .img_cash_count {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px !important;
}

.add_dynaminc_category .img_cash_count .h_logo {
    width: 100px;
    height: 100px;
}

.add_dynaminc_category .img_cash_count .h_logo img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.add_dynaminc_category .img_cash_count .barnd_name {
    color: #0B7702;
    text-align: center;
    margin: auto 0;
}

.Brand_name_not_center {
    color: #0B7702;
    text-align: auto;
    margin: auto 0;
}

.Brand_name_not_center h5 {
    font-weight: 600;
    font-size: 23px;
}

.Brand_name_not_center span {
    text-transform: capitalize;
    font-size: 17px;
}

.add_dynaminc_category .img_cash_count .barnd_name h5 {
    font-weight: 600;
    font-size: 23px;
}

.add_dynaminc_category .img_cash_count .barnd_name span {
    text-transform: capitalize;
    font-size: 17px;
}

.add_dynaminc_category .img_cash_count .b_logo {
    width: 100px;
    height: 100px;
}

.add_dynaminc_category .img_cash_count .b_logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.add_dynaminc_category .img_text_top .h_logo {
    width: 100px;
    height: 100px;
}

.add_dynaminc_category .img_text_top .h_logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.add_dynaminc_category .img_text_top .barnd_name {
    color: #0B7702;
    margin: auto 0;
    text-align: center;
}

.add_dynaminc_category .img_text_top .barnd_name h5 {
    font-weight: 600;
    font-size: 23px;
}

.add_dynaminc_category .img_text_top .barnd_name span {
    text-transform: capitalize;
    font-size: 17px;
}

.dynamic-button {
    margin-right: 40px !important;
}

.add_dynaminc_category .img_text_top .b_logo {
    width: 100px;
    height: 100px;
}

.add_dynaminc_category .img_text_top .b_logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.add_dynaminc_category .h_address {
    margin: 30px auto 50px;
}

.add_dynaminc_category .h_address p {
    font-size: 20px;
    font-weight: 500;
}

.dynamic_row_category {
    color: #0B7702;
}

.dynamic_row_category h4 {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 600;
}

.dynamic_row_category .pluse_minus {
    display: flex;
    justify-content: flex-start;
    margin-left: 30px;
}

.dynamic_row_category .pluse_minus button {
    background-color: #0B7702;
    color: #fff;
    padding: 3px 10px;
    font-size: 20px;
    border-radius: 7px;
}

.dynamic_row_category .pluse_minus button {
    box-shadow: none;
}

.dy_save {
    background-color: #0B7702 !important;
    color: white !important;
    /* width: 140px; */
    height: 45px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 10px !important;
    margin: 40px 0px;
}

.footer_div {
    background-color: #0B7702;
    padding: 20px;
    display: flex;
    justify-content: center;
}

.footer_div span {
    color: #fff;
    font-size: 18px;
}

.user_permission {
    color: #0B7702 !important;
    font-weight: bold;
    font-size: 15px;
    width: fit-content !important;
    position: absolute;
    top: 0px;
    left: 0px;
}

.guestorder_permission {
    color: #010004;
    font-weight: 500;
    font-size: 15px;
    width: -moz-fit-content !important;
    position: absolute;
    top: 0px;
    right: 2px;
    font-weight: 700;
}

/* .guestorder_permission_header{
    color: #7c7878;
    position: absolute;
    top: 0px;
    right: 2px;
} */

.list_of_category {
    padding: 20px;
}

.list_of_category .title_head {
    font-size: 20px;
    color: #0B7702;
    font-weight: 600;
}

HEAD .list_of_category {}

.list_of_category {}

.list_of_category {}

.list_of_category .title_head1 {
    font-size: 18px;
    color: #000;
    font-weight: 600;
}

.admin_false .cross_icon {
    width: 120px;
    height: 120px;
    margin: auto;
}

.admin_false .cross_icon img {
    width: 100%;
    height: 100%;
}

.admin_false {
    width: 300px;
    height: auto;
    margin: auto;
}

.admin_false i {
    color: red;
}

.fileupload_modal_caro {
    position: relative;
}

.fileupload_modal_caro a {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #0B7702 !important;
    color: white;
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 0px 0px 0px 20px;
    border: 1px solid #fff;
}

.fileupload_modal_caro img {
    object-fit: contain;
}

.fileupload_modal_caro a i {
    position: relative;
    top: 8px;
    left: 14px;
}

.fa-picture-o {
    color: #0B7702 !important;
    font-size: 22px;
}

.areaamenity_increment {
    font-size: 28px;
    color: #0B7702 !important;
}

.date_tim_for .formate .title {
    color: #000;
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 20px;
}

.date_tim_for .formate .lists input {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 50% !important;
}

.date_tim_for .formate .lists .form-check-input:checked {
    background-color: #0B7702;
    border-color: #0B7702;
}

.date_tim_for .formate .lists label {
    margin-left: 12px;
    font-size: 16px;
    margin-top: 4px;
    cursor: pointer;
}

.date_tim_for .formate .lists span {
    background: #d3d3d3;
    padding: 1px 11px;
    font-size: 14px;
    margin-left: 15px;
    font-weight: 600;
}

.dynamic_row_category .padd_lef {
    padding-left: 0px;
}

.dynamic_row_category .padi_right {
    padding-right: 0px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dynamic_row_category .padd_lef {
        padding-left: 7.5px;
    }

    .dynamic_row_category .padi_right {
        padding-right: 7.5px;
    }

    .dynamic_row_category .pluse_minus {
        margin-left: 0px;
    }
}

.dl-list {
    width: 100% !important;
}

.text-danger::before {
    color: red;
}

.pagination-table {
    width: 100%;
    height: 50px;
    border: none !important;
}

.pagination-table {
    border: none !important;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
}

.pagination-style {
    float: right !important;
    padding-top: 13px !important;
    font-weight: bolder !important;
    color: #333333;
    padding-right: 10px;
}

.list_cat .table> :not(:first-child) {
    border: none !important;
}

.pagination-table .fa-angle-right {
    font-size: 17px;
}

.card-body-cash-count {
    padding: 20px 0px;
    flex: 1 1 auto;
    position: relative;
}

.cashcount_notes {
    display: block;
    width: 370px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* .cashcount_amount{
position: relative;
}
.cashcount_hover_amount{
    display: none;
}
.cashcount_amount:hover .cashcount_hover_amount{
display: block;

}
.cashcount_hover_amount{
    background-color: white;
    width: 300px;
    height: 600px;
    position: absolute;
    right: 30px;
} */
.dropdowncashcount {
    position: relative;
    display: inline-block;
}

.dropdown-contentcashcount {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 12px;
    z-index: 1;
    position: absolute;
    right: 0px;
    width: 527px;
    height: 300px;
    overflow: scroll;
}

.dropdown-contentcashcount-description {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 12px;
    z-index: 1;
    position: absolute;
    right: 0px;
    width: 527px;
    height: 150px;
    overflow: scroll;
}

.dropdown-contentcashcount-description-auto {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 12px;
    z-index: 1;
    position: absolute;
    right: 0px;
    width: 527px;
    height: auto;
    overflow: scroll;
}

.dropdowncashcount:hover .dropdown-contentcashcount {
    display: block;
}

.dropdowncashcount:hover .dropdown-contentcashcount-description {
    display: block;
}

.dropdowncashcount:hover .dropdown-contentcashcount-description-auto {
    display: block;
}

.over-flow-table {
    width: 500px !important;
}

.otas_image {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.otas_image img {
    width: 100%;
    height: 100%;
}

.sent_tickets {
    padding: 8px 20px;
    font-size: 13px;
}

.sent_tickets {
    cursor: pointer;
}

.sent_card {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.qumi {
    background-color: #0B7702;
    border-radius: 12px;
    margin-right: 8px;
    color: #fff;
}

.qupl {
    background-color: #0B7702;
    border-radius: 12px;
    margin-left: 8px;
    color: #fff;
}

.qumi:hover,
.qupl:hover {
    color: #fff;
}

.review-response {
    color: red;
    font-size: 12px;
    position: relative;
    top: 10px;
    justify-content: end;
    font-style: normal !important;
    font-weight: 500;
    padding: 0px !important;
}

.notes_update_cash_count {
    height: 100px;
    overflow: scroll;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    padding: 20px;
    border-radius: 20px;
}

.notes_update_cash_count_auto {
    height: auto;
    overflow: scroll;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    padding: 20px;
    border-radius: 20px;
}

.cash_count_card button {
    width: auto !important;
    margin: auto;
}

.cash_count_card {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.cash_count_card .card-footer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.cash_count_hotel_logo {}

.cash_image {
    width: 100px;
    height: 100px;
    object-fit: contain !important;
}

.cash_image img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
}

.cash_count_hotel_name {
    font-weight: bolder;
    text-align: center;
    margin-top: 10px;
}

.cash_count_hotel_logo {
    height: 100px;
    width: 100px;
    margin: auto;
}

.border_list_cash_count {
    border-right: 1px solid #ccc;
}

.card-body-cash-count {
    padding: 20px 20px;
    flex: 1 1 auto;
    position: relative;
}

.sentimental_seemore {
    font-weight: bolder;
    width: auto !important;
}

.senti_search {
    position: relative;
}

.senti_search input {
    padding-left: 55px;
}

.search_room_type input {
    padding-left: 38px;
    color: hsl(0, 0%, 20%);
}

.serach_by_room_rate {
    position: absolute;
    left: 21px;
    font-size: 17px;
    top: 12px;
    color: #bbb;
}

.senti_serch_icon {
    position: absolute;
    left: 25px;
    font-size: 20px;
    top: 37px;
    color: #bbb;
}

.senti_btn_view {
    padding: 5px 30px;
    background-color: #449bed;
    color: white;
    position: relative;
    border-radius: 1.35rem !important;
}

.sentiment_badge_count {
    position: inherit !important;
    color: #0B7702 !important;
    padding: 0px 5px !important;
    margin-left: 20px !important;
    margin-bottom: 14px !important;
}

.senti_btn_view:hover {
    color: white !important;
}

.viewrepdf {
    height: 45px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 10px;
    border: 1px solid #0B7702;
    color: #0B7702;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #0B7702 !important;
    background-color: #F3FDE8 !important;
    font-weight: 600 !important;
    border: 1px solid #0B7702;
}

.cash_count_up_arrow::before {
    color: green !important;
}

.cash_count_down_arrow::before {
    color: red !important;
}

.already_review {
    font-size: 21px !important;
    font-weight: 600 !important;
}

.nav-link .badge,
.nav-item .badge {
    border: 1px solid #0B7702 !important;
}

.card_body_report {
    padding: 10px 0px 25px 0px !important;
}

.title_color_report {
    font-weight: 600 !important;
}

.all_invites_flex {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.swal_title_surebulk_review {
    margin: 15px auto 10px !important;
    position: absolute;
    left: 20px;
    top: 30px;
}

.date_calendar_status {
    color: #0B7702;
    font-weight: 800 !important;
    font-size: 15px !important;
    vertical-align: middle;
    background: #d7eaff !important;
    /* border: 2px solid #0B7702 !important;
    border-left: 2px solid #0B7702 !important; */
    text-align: center !important;
}

.icon_class_select {
    font-weight: bold !important;
    font-size: 21px !important;
}

.cancellation_room_rate {
    border: 1px solid #ccc;
    padding: 20px;
}

.cancellation_room_rate label {
    color: #0B7702;
    font-weight: 600;
    font-size: 18px;
}

.cancellation_paragraph {
    border: 1px solid #0B7702;
    padding: 10px !important;
    background: #f3f3f3;
}

.dashborad_room_rate {
    border-bottom: 1px solid #e8e9e9 !important;
}

.calendar_fuction {
    display: flex !important;
}

.border_line_table {
    border: 1px solid #e8e9e9 !important;
    text-align: center !important;
}

.setting-report {
    text-align: center;
}

.report_table_open_count thead tr {
    background: #d7eaff !important;
}

.senti_scroll .react-multi-carousel-item {
    width: auto !important;
}

.senti_scroll .react-multi-carousel-list {
    width: auto;
}

.senti_scroll .react-multiple-carousel__arrow:hover {
    background-color: #d7eaff !important;
    color: #0B7702 !important;
}

.senti_scroll .react-multiple-carousel__arrow {
    top: 7px !important;
    background-color: #d7eaff !important;
    border: 1px solid #0B7702 !important;
}

.senti_scroll .react-multiple-carousel__arrow::before {
    color: #0B7702 !important;
}

.senti_scroll .react-multiple-carousel__arrow--left {
    top: 7px !important;
}

.onchange_sentimental_value {
    background-color: #d7eaff !important;
    border: 1px solid #0B7702 !important;
    color: #0B7702 !important;
}

.onchange_sentimental_value:hover {
    color: #0B7702 !important;
}

.progress-bar-stepform {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    transition: width 0.6s ease;
    background-color: #0B7702;
}

.transition-stepform {
    transition-property: opacity, left;
    transition-duration: 2s;
}

.room_rate_input_type {
    border: none !important;
    border-radius: 0px !important;
    border-bottom: 1px solid #868686 !important;
    background: none !important;
    width: 60px !important;
    text-align: center;
    margin-left: 10px !important;
}

.room_rate_checkbox_hover {
    cursor: pointer !important;
}

.table_height_change_room_rate {
    height: 100px !important;
}

.amenity-box {
    background-color: #d7eaff !important;
    height: 80px;
    /* width: 130px; */
    padding: 0px;
    /* border-radius: 30px; */
    border-radius: 5px !important;
    box-shadow: 1px 1px #0B7702 !important;
}

.button_width_Room_rate {
    width: auto !important;
    height: 45px !important;
    border-radius: 14px !important;
    background-color: #0B7702 !important;
    color: white !important;
    float: right;
    font-size: 18px;
    font-weight: 500;
}

.time_room_type {
    position: relative;
}

.table_Overflow_x {
    overflow-x: auto !important;
}

.margim_top_design {
    margin-top: 26px !important;
}

.no_drop_item_disable {
    cursor: no-drop !important;
}

.Room_Rate_Clock {
    cursor: pointer;
    font-size: 20px;
    opacity: 0.7;
    position: absolute;
    top: 11px;
    right: 20px;
    color: #b5b5b5;
}

.icon_percentages {
    position: absolute !important;
    right: 17px !important;
    top: 14px !important;
    color: #dadada !important;
}

.time_room_type input {
    padding-left: 38px;
    color: hsl(0, 0%, 20%);
}

.time_by_room_rate {
    position: absolute;
    left: 16px;
    font-size: 17px;
    top: 13px;
    color: #b5b5b5;
}

.icon-stepform {
    width: 20px;
    height: 20px;
}

.room_rate .table_height_change_room_rate td {
    font-size: 10px !important;
}

.room_rate .table thead th {
    font-size: 11px !important;
}

.dicount-stepform {
    border: 2px solid #0B7702 !important;
    border-radius: 10px;
    padding: 9px;
    margin: 40px;
    background-color: aliceblue;
    display: inline-block;
    margin-bottom: 10px;
    width: 200px;
    text-align: center;
    cursor: pointer;
}

.roomtype-setting-amenity {
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 17px;
}

.upload-drag-drop {
    border: 2px dashed #ccc;
    /* padding: 70px; */
    color: #0B7702 !important;
    cursor: pointer;
    width: 360px;
    height: 370px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-drag-drop img {
    width: 360px;
    height: 300px;
    opacity: 0.3;
}

.roomtype-tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.roomtype-tooltip .tooltiptext {
    visibility: hidden;
    width: 250px !important;
    background-color: #0B7702;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    margin-top: 30px;
    z-index: 1;
}

.roomtype-tooltip:hover .tooltiptext {
    visibility: visible;
}

.roomtype-tooltip i {
    background-color: #0B7702;
    color: white;
    padding: 3px;
}

.text_center_afterbefore {
    text-align: center;
    margin: 25px 0px 15px 0px;
}

.text_center_afterbefore p::before,
.text_center_afterbefore p::after {
    display: inline-block;
    content: "";
    border-top: 1px solid #ccc;
    width: 500px;
    margin: 0px 5px;
    transform: translateY(-0.3rem);
}

.row_table_virtual {
    /* padding: 15px; */
    margin: 5px;
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
}

.row_inlinetable_virtual {
    border-right: 1px solid #ccc !important;
    padding: 15px 20px 10px 20px;
    border-bottom: 1px solid #ccc !important;
    position: relative !important;
}

.and_classname_virtual {
    padding: 0px 10px;
    position: absolute !important;
    left: 460px;
    background: white;
}

.buttom_table_in_virtual {
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ccc;
    display: flex !important;
}

.room_rate_header {
    color: #0B7702 !important;
    font-weight: 800 !important;
    font-size: 15px !important;
}

.img_remove_roomtype {
    position: absolute;
    top: 0px;
    z-index: 1;
    right: 0px;
    font-size: 22px;
    color: #0B7702;
    background-color: #fff;
    cursor: pointer;
}

.modal-body .setting-roomimage-preview {
    border: 2px dashed #ccc;
    cursor: pointer;
    padding: 10px;
}

.roomtype-image-div {
    height: 100px;
    margin-left: 9px;
    position: relative;
    width: 120px;
}

.roomtype-image-div img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.card .card-options .multiple {
    height: 45px !important;
    width: 200px !important;
    border-radius: 14px !important;
    background-color: #0B7702 !important;
    color: white !important;
    float: right;
    font-size: 18px;
    font-weight: 500;
}

.setting-room {
    color: #0B7702 !important;
    font-size: 25px !important;
    padding: 3rem 1rem 0rem 4rem !important;
}

.vendor-style {
    background-color: #d7eaff !important;
    height: 300px;
}

.vendor-style h6 {
    margin-top: 10px;
    color: #0B7702;
    font-weight: 600;
}

.vendor-style span {
    margin-top: 10px;
    color: #0B7702;
}

.vendor-style button {
    margin-top: 10px;
    background-color: #0B7702 !important;
    border-radius: 4px;
    color: white;
}

.vendor-style .btn.btn-default {
    border-color: #e8e9e9;
    padding: 6px 18px;
    color: #fff;
}

.confi_guration {
    margin-left: 100px !important;
    margin-top: 10px !important;
}

.config-redirect:hover {
    text-decoration: underline;
}

.vendor_save {
    background-color: #0B7702 !important;
    color: white !important;
    height: 45px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 10px !important;
    margin-bottom: 40px;
}

.vendor-thanks {
    margin: 150px 0px;
    text-align: center;
}

.vendor-timeout-thanks {
    margin: 89px 0;
    text-align: center;
}

.language_editinput {
    padding: 5px 15px;
    border: 1px solid #ccc;
    background: none !important;
}

.language_editinput:focus {
    outline: none !important;
}

#language_Scrol {
    overflow: auto;
    white-space: nowrap;
    height: 350px;
    margin-bottom: 20px;
}

.language_border {
    border: 2px solid #ccc;
    padding: 40px 40px 20px 40px;
    position: relative;
}

#language_Scrol::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
    transition: 0.3s background;
    border: 2px solid #ccc;
}

#language_Scrol::-webkit-scrollbar-thumb {
    background: #0B7702 !important;
}

#language_Scrol:hover::-webkit-scrollbar-thumb {
    background: #0B7702 !important;
}

/* ======================= */
.ticket_flow_dynamic_radio_btn .form-check-input:checked {
    background-color: #0B7702;
    border-color: #333333;
}

.mobile_permission_icon {
    font-size: 25px;
    color: #0B7702;
    position: absolute;
}

.senti_scroll .nav-link .badge,
.nav-item .badge {
    min-width: auto !important;
}

.bad_review_star {
    margin-bottom: 12px;
    display: flex;
}

.bad_review_star i {
    margin-right: 10px;
    font-size: 20px;
    cursor: pointer;
}

/* e4bd51  */
.table_booked {
    width: 280px;
}

.row_label_changes p {
    margin: 10px 0px;
}

.canvas_footer_button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.front-desk-tooltip {
    color: #0B7702 !important;
    font-size: 18px !important;
    background-color: #fff !important;
    cursor: pointer;
}

.copy_booking_pin_code {
    border: 2px solid #0B7702;
    padding: 5px 11px;
    color: #0B7702;
    margin-left: 20px;
    cursor: pointer;
    border-radius: 10px;
}

.Brand_name_Form {
    color: #0B7702;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 10px;
}

.Brand_name_Form h5 {
    font-weight: 600;
    font-size: 23px;
    margin-bottom: 30px;
}

.icon_vendor_singup {
    cursor: pointer;
    font-size: 15px !important;
}

.Brand_name_Form span {
    margin-top: 10px;
    text-transform: capitalize;
}

.product_vendor {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.uploaded_btn_room_rate {
    margin-bottom: 10px;
}

.uploaded_btn_room_rate button {
    width: 150px !important;
    font-size: 13px;
}

.year_calendar_left {
    height: 40px;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    /* color: hsl(0, 0%, 20%); */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #868686;
    font-weight: bold;
}

.year_calendar_name {
    height: 40px;
    background: linear-gradient(to top, #F3FDE8 0%, #fff 35%);
    /* color: hsl(0, 0%, 20%); */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #868686;
    font-weight: bold;
    color: #aaa;
}

.year_calendar_name_active {
    color: #fff;
    background: #0B7702;
}

.calendar_year .col {
    padding: 0px !important;
    cursor: pointer;
}

.calendar_year .row {
    padding: 0px 10px !important;
}

.calendar_card_body {
    padding: 10px 20px 5px 20px;
    flex: 1 1 auto;
    position: relative;
}

.hotel_front_desk {
    white-space: nowrap;
    width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.font_desk_header {
    cursor: pointer;
    color: #0B7702 !important;
    font-weight: 800 !important;
    font-size: 15px !important;
}

.Hotel_name_one_line {
    width: 11vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    color: #0B7702 !important;
    font-weight: 800 !important;
    font-size: 15px !important;
}

.Hotel_name_line {
    width: 11vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px !important;
}

.active_color_table {
    background: #0B7702 !important;
    color: #fff !important;
}

.advanced_booking_pages {
    display: flex !important;
    align-items: center !important;
    border: 1px solid #0B7702 !important;
    padding: 0px 50px !important;
    cursor: pointer;
}

.advanced_booking_pages label {
    margin-bottom: 0px;
    color: #0B7702;
    cursor: pointer;
}

.font_lavbel_quick {
    margin: 8px 0px 12px 0px;
    font-weight: 400;
}

.offcanvas.show {
    transform: none;
    /* width: 60%; */
}

.position_fixed_cnavas {
    position: sticky;
    bottom: -20px;
    background: #fff;
}

.phone_num_drop {
    position: absolute;
    z-index: 1;
    background: #fff;
    width: 98%;
    border: 1px solid #b4a9a9;
    border-radius: 10px;
}

.phone_num_drop ul {
    display: inline-block;
    padding: 10px;
    margin: 0px;
    width: 100%;
}

.phone_num_drop ul li {
    display: block;
    padding: 4px;
    list-style-type: none;
    cursor: pointer !important;
}

.phone_num_drop ul li:hover {
    display: inline-block;
    padding: 4px;
    background-color: #d7eaff;
    list-style-type: none;
    width: 100%;
    cursor: context-menu;
}

.dropdown_scroll_guest {
    width: 97%;
    background-color: #fff;
    overflow: auto;
    height: 175px;
}

.dropdown_scroll_guest::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
    transition: 0.3s background;
    border: 2px solid #ccc;
}

.dropdown_scroll_guest::-webkit-scrollbar-thumb {
    background: #0B7702 !important;
}

.dropdown_scroll_guest:hover::-webkit-scrollbar-thumb {
    background: #0B7702 !important;
}

.card-order-style {
    padding: 0px !important;
}

.guest_invite_heading {
    text-align: center;
}

.guest_dynamic_text {
    margin-bottom: 20px;
}

.button_auto_front_desk {
    background-color: #0B7702 !important;
    color: white !important;
    float: right;
    width: auto !important;
    font-size: 18px;
    font-weight: 500;
    height: 45px !important;
    border-radius: 14px !important;
}

.scheduler_default_rowheader_inner {
    padding-right: 20px;
}

.details_show_booking {
    border: 1px solid #0B7702;
    padding: 10px 20px;

    background-color: #f9f9f9;
}

.swal_title_front_desk_check_out {
    color: #0B7702 !important;
    font-size: 20px !important;
    padding: 2.5rem 4rem 0rem 7rem !important;
}

.position_fixed_cnavas_small_contant {
    padding: 0px 0px !important;
    position: fixed !important;
    bottom: 0px !important;
    width: -webkit-fill-available !important;
    background: white !important;
    z-index: 999 !important;
}

.table_Size_invoice th,
.table_Size_invoice td {
    font-size: 13px !important;
}

.total_price_data {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px 0px 100px 0px;
    background-color: #F5F7FC;
}

.guest-invite-hotellogo {
    display: flex;
    justify-content: center !important;
    padding: 10px 0px;
}

.boxer_booking .nav-link {}

.step1_btn li .nav-link {
    border-radius: 0px !important;
}

.step1_btn li .nav-link.active {
    border-radius: 0px !important;
}

.step1_btn li .nav-link:hover {
    border-radius: 0px !important;
}

.booking_added .bulk_tab li {}

.booking_added .bulk_tab li .nav-link {
    background-color: #d7eaff !important;
    color: #0B7702 !important;
    height: 45px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    border-radius: 0px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0px 15px !important;
}

.tables_slected_invoice td {
    background: #d7eaff !important;
}

.or_classname_virtual {
    position: absolute;
    left: 39px;
    background: white !important;
    padding: 0px 8px !important;
    top: 67px;
    z-index: 9;
}

.booking_added .bulk_tab li .nav-link:hover {
    background-color: #0B7702 !important;
    color: #fff !important;
    height: 45px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    border-radius: 0px !important;
    border: none !important;
}

.booking_added .bulk_tab .nav-link.active {
    border: none !important;
    background-color: #0B7702 !important;
    color: #fff !important;
    border-radius: 0px !important;
}

.serach_booking {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
}

.serach_booking .commor_save_search {
    background-color: #0B7702 !important;
    color: white !important;
    width: 200px;
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px !important;
}

.booking_tables thead th {
    color: #0B7702;
    font-weight: 800 !important;
    font-size: 13px !important;
    vertical-align: middle;
}

.booking_tables tbody td {
    font-size: 13px !important;
    font-weight: 600 !important;
}

.add_table_icon_reservation {
    font-size: 25px;
    padding-bottom: 13px;
    display: flex;
    justify-content: center;
}

.add_table_icon_reservation span {
    margin: 0px 10px;
    cursor: pointer;
    color: #0B7702;
}

.reservation_dashboard {
    background: #d7eaff;
    padding: 13px 0px;
    border-radius: 30px;
    margin: 15px 0px 30px 0px;
}

.reservation_dashboard p {
    margin-bottom: 0px;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
}

.food-dynamic {
    height: 40px;
    width: auto !important;
    display: inline !important;
    border: 1px solid #868686;
    font-size: 18px !important;
}

.box_shadow_reservation {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px,
        rgba(17, 17, 26, 0.1) 0px 24px 80px !important;
}

.reservation_display_align {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.box_shadow_reservation .round_profile {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: auto !important;
}

.door_loock_reservation {
    display: flex;
    justify-content: space-between;
    margin: 30px 0px 15px 0px;
    align-items: center;
}

.door_loock_reservation P {
    font-weight: 700 !important;
    font-size: 13px !important;
}

.door_loock_reservation_label {
    font-weight: bold !important;
    font-size: 15px !important;
}

.share_link_reservation {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.share_link_reservation_btn {
    border: 1px solid #0B7702;
    padding: 10px 6px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
    color: #0B7702;
}

.edit_view_reservation:hover {
    color: white !important;
}

.edit_view_reservation_adjust {
    position: absolute !important;
    transform: translate3d(337px, 40px, 0px) !important;
    top: 0px !important;
    left: -180px !important;
}

.add_icon_reservation {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
}

.add_icon_reservation i {
    font-size: 50px !important;
    color: #0B7702 !important;
    cursor: pointer;
}

.reservation_carousel {
    padding: 0px;
}

.reservation_carousel_added_icon {
    margin: auto;
    margin-top: 140px;
}

.form-border-color {
    border-bottom: 1px solid #ccc;
    margin: 10px 0px 20px 0px;
}

.not_show_guest_search {
    display: flex !important;
    padding: 4px !important;
    justify-content: center !important;
    list-style-type: none !important;
    align-items: center !important;
}

.not_show_guest_search:hover {
    display: flex !important;
    padding: 4px !important;
    justify-content: center !important;
    list-style-type: none !important;
    align-items: center !important;
    width: 100% !important;
    cursor: context-menu !important;
    background-color: #fff !important;
}

.reservation_display_align h4 {
    width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: end;
}

.reservation_display_align label {
    width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
}

.template_content {
    border: 1px solid #0B7702;
    padding: 20px 20px;
    border-radius: 10px;
}

.padding_reservation_width {
    padding: 0px;
}

/* .reservation_carousel .react-multi-carousel-list {
    padding: 0px;
} */

.stripeData .FormGroup {
    margin: 0 15px 20px;
    padding: 0;
    border-style: none;
    background-color: #7795f8;
    will-change: opacity, transform;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #829fff;
    border-radius: 4px;
}

.stripeData .FormRow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    border-top: 1px solid #819efc;
}

.stripeData .StripeElement--webkit-autofill {
    background: transparent !important;
}

.stripeData .StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
}

.stripeData button {
    display: block;
    font-size: 16px;
    width: calc(100% - 30px);
    height: 40px;
    margin: 40px 15px 0;
    background-color: #f6a4eb;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #ffb9f6;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    will-change: transform, background-color, box-shadow;
    border: none;
}

.stripeData button:active {
    background-color: #d782d9;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #e298d8;
    transform: scale(0.99);
}

.totalamount_currency_labels {
    text-align: right;
    margin: 15px 25px 0px 15px;
    font-size: 17px;
    font-weight: 600;
}

.uploaded_Successfully_invoice {
    font-weight: bold;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 400px;
}


.table_room_rate thead th {
    color: #0B7702;
    font-weight: 800 !important;
    font-size: 12.5px !important;
    vertical-align: middle;
}

.table_room_rate thead td {
    vertical-align: middle;
}

.table_room_rate.table_room_rate-hover tbody tr:hover {
    background: none !important;
}

.table_room_rate td,
.table_room_rate th {
    font-size: 12px !important;
}

.table_room_rate> :not(:first-child) {
    border-top: 1.1px solid #0B7702 !important;
}

.uploaded_Successfully_invoice i,
.uploaded_Successfully_invoice p {
    color: #0B7702 !important;
}

.uploaded_Successfully_invoice i {
    font-size: 50px !important;
    margin-bottom: 20px;
}

.invoice_bles_design {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
    border-bottom: none !important;
}

.invoice_bles_design label {
    margin: 0px;
}

.invoice_number_icon {
    border: 1px solid #ccc;
    padding: 3px 15px;
    cursor: pointer;
    margin-left: 10px;
}

.invoice_number_icon i {
    font-size: 18px !important;
}

.stripeData h1,
h3 {
    text-align: center;
}

.box_shadow_reservation .round_profile img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    padding: 10px;
}

.label_language {
    white-space: nowrap;
    width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.phoneerror {
    margin-top: -14px;
    margin-left: 197px;
}

.gif-css {
    color: #010004 !important;
    font-size: 100px;
}

.card .card-options a.dropdown-item {
    color: #0B7702 !important;
}

.card .card-options a.dropdown-item:hover {
    color: #fff !important;
}

/* .page-header{
	display: none !important;
} */
.not-confirm {
    width: 115px !important;
}

/* #main_content .page{
    left:0px !important;
    
    width: 100% !important;
} */
.fa-commenting {
    font-size: 18px !important;
    margin-left: 6px;
}

.container-fluid {
    padding: 0px;
}

.ag_grid_no_record_show {
    position: relative;
}

.ag_grid_no_record_show_span {
    position: absolute;
    top: 50%;
    left: 45%;
}

.Forgot_Password {
    color: #0B7702;
    cursor: pointer;
}

.Forgot_Password:hover {
    color: #0B7702;
}

.offcanvas-active .page {
    left: 60px !important;
    width: calc(100% - 60px) !important;
}

.auth {
    background-color: #F3FDE8 !important;
}